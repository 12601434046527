import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as DropInCheckoutActions from './actions';
import { appRoot } from '~/constants/defaultValues';
import api from '~/services/api';
import history from '~/services/history';

export function* createDropInCheckout({ payload }) {
  try {
    const {
      name,
      productName,
      productImage,
      productDescription,
      pixels,
      amount,
      shippingFee,
      reviews,
      reviewsImages,
      timer,
      paymentTypes,
    } = payload;
    const formData = new FormData();

    formData.append('amount', amount);
    formData.append('shippingFee', shippingFee);
    formData.append('productName', productName);
    formData.append('productImage', productImage);
    formData.append('name', name);
    formData.append('productDescription', productDescription);
    formData.append('timer', JSON.stringify(timer));
    reviews.forEach((element, index) => {
      formData.append('reviews[]', JSON.stringify(element));
      formData.append('reviewsImages', reviewsImages[index]);
    });
    paymentTypes.forEach((element) => {
      if (element) {
        formData.append('paymentTypes[]', JSON.stringify(element));
      }
    });
    pixels.forEach((element) => {
      if (element) {
        formData.append('pixels[]', element);
      }
    });

    const { data } = yield call(api.post, '/drop_in_checkout', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    toast.success('Checkout criado com sucesso.');
    yield put(DropInCheckoutActions.createDropInCheckoutSuccess());
    history.replace(`${appRoot}/checkouts`);
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
    );
    yield put(DropInCheckoutActions.failure({ status: err?.response?.status }));
  }
}

export function* updateDropInCheckout({ payload }) {
  try {
    const {
      path,
      name,
      productName,
      productImage,
      productDescription,
      pixels,
      amount,
      shippingFee,
      reviews,
      reviewsImages,
      timer,
      paymentTypes,
    } = payload;
    const formData = new FormData();

    formData.append('amount', amount);
    formData.append('shippingFee', shippingFee);
    formData.append('productName', productName);
    formData.append('productImage', productImage);
    formData.append('name', name);
    formData.append('productDescription', productDescription);
    formData.append('timer', JSON.stringify(timer));
    reviews.forEach((element, index) => {
      formData.append('reviews[]', JSON.stringify(element));
      formData.append('reviewsImages', reviewsImages[index]);
    });
    paymentTypes.forEach((element) => {
      if (element) {
        formData.append('paymentTypes[]', JSON.stringify(element));
      }
    });
    pixels.forEach((element) => {
      if (element) {
        formData.append('pixels[]', element);
      }
    });

    const { data } = yield call(
      api.put,
      `/drop_in_checkout/${path}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    toast.success('Checkout criado com sucesso.');
    yield put(DropInCheckoutActions.updateDropInCheckoutSuccess());
    history.go(0);
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
    );
    yield put(DropInCheckoutActions.failure({ status: err?.response?.status }));
  }
}

export function* failure({ payload }) {
  const { status } = payload;

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export default all([
  takeLatest(
    '@dropInCheckout/CREATE_DROP_IN_CHECKOUT_REQUEST',
    createDropInCheckout
  ),
  takeLatest(
    '@dropInCheckout/UPDATE_DROP_IN_CHECKOUT_REQUEST',
    updateDropInCheckout
  ),
  takeLatest('@dropInCheckout/FAILURE', failure),
]);

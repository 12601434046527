import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as CustomerAuth from './actions';
import api from '~/services/api';
import history from '~/services/history';

export function* signIn({ payload }) {
  try {
    const { doc, password, userId } = payload;

    const { data } = yield call(api.post, '/payment_bonds/login', {
      doc,
      password,
      userId,
    });

    api.defaults.headers.Authorization = `Bearer ${data.token}`;

    yield put(
      CustomerAuth.signInSuccess({
        token: data.token,
        doc,
      })
    );

    history.push('/payment-bonds/result');
  } catch (err) {
    toast.error(
      'CPF/CNPJ e/ou senha informados estão incorretos ou inválidos.'
    );

    yield put(CustomerAuth.failure());
  }
}

export function setToken({ payload }) {
  if (!payload) {
    return;
  }

  const { token } = payload.customer;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('@customer/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
]);

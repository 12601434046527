import Types from './types';

export const createLoginProfilesRequest = ({ name, email, password }) => ({
  type: Types.CREATE_LOGIN_PROFILE_REQUEST,
  payload: {
    name,
    email,
    password,
  },
});

export const createLoginProfilesSuccess = () => ({
  type: Types.CREATE_LOGIN_PROFILE_SUCCESS,
  payload: {},
});

export const deleteLoginProfilesRequest = ({ id }) => ({
  type: Types.DELETE_LOGIN_PROFILE_REQUEST,
  payload: {
    id,
  },
});

export const deleteLoginProfilesSuccess = () => ({
  type: Types.DELETE_LOGIN_PROFILE_SUCCESS,
  payload: {},
});

export function failure({ status }) {
  return {
    type: Types.FAILURE,
    payload: { status },
  };
}

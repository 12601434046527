export const createRecurrencePlanRequest = ({
  name,
  description,
  billingType,
  interval,
  intervalCount,
  amount,
  trialPeriodDays,
  paymentTypes,
  cyclesToSuspend,
  daysToCancel,
  splits,
}) => ({
  type: '@recurrence/CREATE_RECURRENCE_PLAN_REQUEST',
  payload: {
    name,
    description,
    billingType,
    interval,
    intervalCount,
    amount,
    trialPeriodDays,
    paymentTypes,
    cyclesToSuspend,
    daysToCancel,
    splits,
  },
});

export const createRecurrencePlanSuccess = () => ({
  type: '@recurrence/CREATE_RECURRENCE_PLAN_SUCCESS',
  payload: {},
});

export const updateRecurrencePlanRequest = ({ status }) => ({
  type: '@recurrence/UPDATE_RECURRENCE_PLAN_REQUEST',
  payload: {
    status,
  },
});

export const updateRecurrencePlanSuccess = () => ({
  type: '@recurrence/UPDATE_RECURRENCE_PLAN_SUCCESS',
  payload: {},
});

export const createSubscriptionRequest = ({
  paymentType,
  recurrencePlanId,
  fullName,
  doc,
  email,
  phone,
  postCode,
  street,
  number,
  state,
  city,
  neighborhood,
  complement,
  card,
}) => ({
  type: '@recurrence/CREATE_SUBSCRIPTION_REQUEST',
  payload: {
    paymentType,
    recurrencePlanId,
    fullName,
    doc,
    email,
    phone,
    postCode,
    street,
    number,
    state,
    city,
    neighborhood,
    complement,
    card,
  },
});

export const createSubscriptionSuccess = () => ({
  type: '@recurrence/CREATE_SUBSCRIPTION_SUCCESS',
  payload: {},
});

export const updateSubscriptionRequest = ({ status, id }) => ({
  type: '@recurrence/UPDATE_SUBSCRIPTION_REQUEST',
  payload: { status, id },
});

export const updateSubscriptionSuccess = () => ({
  type: '@recurrence/UPDATE_SUBSCRIPTION_SUCCESS',
  payload: {},
});

export const updateSubscriptionCardRequest = ({ card, token }) => ({
  type: '@recurrence/UPDATE_SUBSCRIPTION_CARD_REQUEST',
  payload: { card, token },
});

export const updateSubscriptionCardSuccess = () => ({
  type: '@recurrence/UPDATE_SUBSCRIPTION_CARD_SUCCESS',
  payload: {},
});

export function failure({ status }) {
  return {
    type: '@recurrence/FAILURE',
    payload: { status },
  };
}

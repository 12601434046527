import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  loading: false,
  balance: null,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        draft.balance = action.payload.balance;

        break;
      }

      case '@user/GET_USER_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/GET_USER_SUCCESS': {
        draft.loading = false;
        draft.profile = {
          ...state.profile,
          ...action.payload.user,
        };
        draft.balance = action.payload.balance;

        break;
      }

      case '@user/SEARCH_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/SEARCH_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/ADMIN_CREATE_USER_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/ADMIN_CREATE_USER_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/ADMIN_UPDATE_USER_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/ADMIN_UPDATE_USER_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/ADMIN_GET_USER_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/ADMIN_GET_USER_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/GET_USERS_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/GET_USERS_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/SUBMIT_DOCUMENT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/SUBMIT_DOCUMENT_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/ADD_BANK_ACCOUNT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/ADD_BANK_ACCOUNT_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@user/FAILURE': {
        draft.loading = false;

        break;
      }

      case '@auth/FAILURE': {
        draft.profile = null;
        draft.balance = null;

        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.balance = null;

        break;
      }

      case '@user/UPDATE_ACCOUNT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@user/UPDATE_ACCOUNT_SUCCESS': {
        draft.loading = false;
        Object.assign(draft.profile, action.payload.account);
        break;
      }

      default:
    }
  });

export default reducer;

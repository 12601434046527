import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@paymentLink/CREATE_PAYMENT_LINK_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@paymentLink/CREATE_PAYMENT_LINK_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@paymentLink/UPDATE_PAYMENT_LINK_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@paymentLink/UPDATE_PAYMENT_LINK_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@paymentLink/FAILURE': {
        draft.loading = false;

        break;
      }

      default:
    }
  });

export default reducer;

export function createWebhookRequest({ type, url }) {
  return {
    type: '@webhook/CREATE_WEBHOOK_REQUEST',
    payload: { type, url },
  };
}

export function createWebhookSuccess() {
  return {
    type: '@webhook/CREATE_WEBHOOK_SUCCESS',
    payload: {},
  };
}

export function deleteWebhookRequest({ id }) {
  return {
    type: '@webhook/DELETE_WEBHOOK_REQUEST',
    payload: { id },
  };
}

export function deleteWebhookSuccess() {
  return {
    type: '@webhook/DELETE_WEBHOOK_SUCCESS',
    payload: {},
  };
}

export function failure({ status }) {
  return {
    type: '@webhook/FAILURE',
    payload: { status },
  };
}

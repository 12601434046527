import { toast } from 'react-toastify';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as RecurrenceActions from './actions';
import { appRoot } from '~/constants/defaultValues';
import AppLocale from '~/lang';
import api from '~/services/api';
import history from '~/services/history';

export function* createRecurrencePlan({ payload }) {
  try {
    const {
      name,
      description,
      billingType,
      interval,
      intervalCount,
      amount,
      cycles,
      trialPeriodDays,
      paymentTypes,
      cyclesToSuspend,
      daysToCancel,
      splits,
    } = payload;

    yield call(api.post, '/recurrence_plans', {
      name,
      description,
      billingType,
      interval,
      intervalCount,
      amount,
      cycles,
      trialPeriodDays,
      paymentTypes,
      cyclesToSuspend,
      daysToCancel,
      splitRules: splits,
    });

    toast.success('Plano criado com sucesso.');
    history.push(`${appRoot}/recurrence`);
    yield put(RecurrenceActions.createRecurrencePlanSuccess());
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.'
    );
    yield put(RecurrenceActions.failure());
  }
}

export function* createSubscription({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];

  try {
    const {
      paymentType,
      recurrencePlanId,
      fullName,
      doc,
      email,
      phone,
      postCode,
      street,
      number,
      state,
      city,
      neighborhood,
      complement,
      card,
    } = payload;

    const { data } = yield call(api.post, '/public/subscriptions', {
      paymentType,
      customer: {
        fullName,
        email,
        phone,
        doc,
        address: {
          city,
          complement,
          neighborhood,
          number,
          postCode,
          state,
          street,
        },
      },
      card,
      recurrencePlanId,
    });

    yield put(RecurrenceActions.createSubscriptionSuccess());

    if (data.status === 'pending' || data.status === 'active') {
      toast.success(messages['alert.subscription.success']);
    } else {
      toast.error(messages['alert.subscription.default-error']);
    }

    history.push({
      pathname: '#/recurrence/checkout/succeeded',
      state: { status: data.status },
    });
  } catch (err) {
    const errorTag = err.response.data?.tag;
    const alert =
      errorTag && errorTag !== '0'
        ? messages[`alert.errors.${errorTag}`]
        : messages['alert.subscription.default-error'];
    toast.error(alert);

    yield put(RecurrenceActions.failure({ status: err?.response?.status }));
  }
}

export function* updateSubscription({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];

  try {
    const { status, id } = payload;

    yield call(api.put, `/subscriptions/${id}`, {
      status,
    });

    yield put(RecurrenceActions.updateSubscriptionSuccess());
    toast.success(messages['alert.subscription.changed-status']);
  } catch (err) {
    const errorTag = err.response.data?.tag;
    const alert =
      errorTag && errorTag !== '0'
        ? messages[`alert.errors.${errorTag}`]
        : messages['alert.subscription.default-error'];
    toast.error(alert);

    yield put(RecurrenceActions.failure({ status: err?.response?.status }));
  }
}

export function* updateSubscriptionCard({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];

  try {
    const { card, token } = payload;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield call(api.put, `/public/subscriptions`, {
      card,
    });

    yield put(RecurrenceActions.updateSubscriptionCardSuccess());
    console.log("AQUI 4")
    toast.success(messages['alert.subscription.changed-card.success']);
  } catch (err) {
    const errorTag = err.response.data?.tag;
    const alert =
      errorTag && errorTag !== '0'
        ? messages[`alert.errors.${errorTag}`]
        : messages['alert.errors.subscription_card_could_not_be_updated'];
    toast.error(alert);

    yield put(RecurrenceActions.failure({ status: err?.response?.status }));
  }
}

export function* failure({ payload }) {
  const { status } = payload || {};

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export default all([
  takeLatest(
    '@recurrence/CREATE_RECURRENCE_PLAN_REQUEST',
    createRecurrencePlan
  ),
  takeLatest('@recurrence/CREATE_SUBSCRIPTION_REQUEST', createSubscription),
  takeLatest('@recurrence/UPDATE_SUBSCRIPTION_REQUEST', updateSubscription),
  takeLatest(
    '@recurrence/UPDATE_SUBSCRIPTION_CARD_REQUEST',
    updateSubscriptionCard
  ),
  takeLatest('@recurrence/FAILURE', failure),
]);

import { toast } from 'react-toastify';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import * as AuthActions from './actions';
import { appRoot } from '~/constants/defaultValues';
import api from '~/services/api';
import history from '~/services/history';
// import history from '~/services/history';

export function* adminSignIn({ payload }) {
  try {
    const { username, password } = payload;

    const { data } = yield call(api.post, 'admin/sessions', {
      username,
      password,
    });

    api.defaults.headers.Authorization = `Bearer ${data.auth.token}`;

    yield put(
      AuthActions.signInSuccess({
        user: data.user,
        token: data.auth.token,
        refreshToken: data.auth.refreshToken,
      })
    );

    history.push(`${appRoot}/`);
  } catch (err) {
    toast.error('Usuário e/ou senha informados estão incorretos ou inválidos.');

    yield put(AuthActions.failure());
  }
}

export function* refreshAdminSignIn({ payload }) {
  try {
    const refreshToken = yield select((state) => state.auth.refreshToken);

    api.defaults.headers.Authorization = `Bearer ${refreshToken}`;

    const { data } = yield call(api.post, 'admin/refresh');

    api.defaults.headers.Authorization = `Bearer ${data.token}`;

    yield put(
      AuthActions.refreshAdminSignInSuccess({
        refreshToken: data.refreshToken,
        token: data.token,
      })
    );
  } catch (err) {
    yield put(AuthActions.failure());
  }
}

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const { data } = yield call(api.post, 'sessions', {
      email,
      password,
      isWeb: true,
    });

    api.defaults.headers.Authorization = `Bearer ${data.auth.token}`;

    yield put(
      AuthActions.signInSuccess({
        user: data.user,
        balance: data.balance,
        token: data.auth.token,
        refreshToken: data.auth.refreshToken,
        loginProfile: data.auth.loginProfile,
      })
    );

    history.push(`${appRoot}/`);
  } catch (err) {
    toast.error('Email e/ou senha informados estão incorretos ou inválidos.');

    yield put(AuthActions.failure());
  }
}

export function* refreshSignIn({ payload }) {
  try {
    const refreshToken = yield select((state) => state.auth.refreshToken);

    api.defaults.headers.Authorization = `Bearer ${refreshToken}`;

    const { data } = yield call(api.post, 'refresh', {
      isWeb: true,
    });

    api.defaults.headers.Authorization = `Bearer ${data.token}`;

    yield put(
      AuthActions.refreshSignInSuccess({
        refreshToken: data.refreshToken,
        token: data.token,
      })
    );
  } catch (err) {
    yield put(AuthActions.failure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      firstName,
      lastName,
      ownerDoc,
      ownerEmail,
      ownerPhone,
      dateBirth,
      ownerAddress,
      statementDescriptor,
      mcc,
      username,
      password,
      businessName,
      businessEmail,
      businessDoc,
      businessPhone,
      businessOpeningDate,
      businessAddress,
    } = payload;

    const { data } = yield call(api.post, 'sellers', {
      doc: businessDoc || ownerDoc,
      username,
      password,
      mcc,
      statementDescriptor,
      businessName,
      businessEmail: businessEmail || ownerEmail,
      businessPhone: businessPhone || ownerPhone,
      owner: {
        firstName,
        lastName,
        doc: ownerDoc,
        email: ownerEmail,
        phone: ownerPhone,
        dateBirth,
        address: {
          city: ownerAddress.city,
          complement: ownerAddress.complement,
          neighborhood: ownerAddress.neighborhood,
          number: ownerAddress.number,
          postCode: ownerAddress.postCode,
          state: ownerAddress.state,
          street: ownerAddress.street,
        },
      },
      address: {
        city: businessAddress.city || ownerAddress.city,
        complement: businessAddress.complement || ownerAddress.complement,
        neighborhood: businessAddress.neighborhood || ownerAddress.neighborhood,
        number: businessAddress.number || ownerAddress.number,
        postCode: businessAddress.postCode || ownerAddress.postCode,
        state: businessAddress.state || ownerAddress.state,
        street: businessAddress.street || ownerAddress.street,
      },
      business_opening_date: businessOpeningDate,
    });

    yield put(AuthActions.signUpSuccess());

    toast.success('Cadastro realizado com sucesso.');

    history.push('/user/login');
  } catch (err) {
    toast.error(
      'Ops... Parece que ocorreu algum erro. Verifique seus dados e/ou tente novamente mais tarde.'
    );

    yield put(AuthActions.failure());
  }
}

export function* forgotPassword({ payload }) {
  try {
    const { doc, next } = payload;

    yield call(api.post, '/forgot_password', {
      doc,
    });

    yield put(AuthActions.forgotPasswordSuccess());
    next();
  } catch (err) {
    const error = err?.response?.data?.message;
    if (err?.response?.status === 400 && error !== 'Unexpected error.') {
      toast.error(error);
    } else {
      toast.error(
        'Ops... Parece que ocorreu algum erro, confira os dados e/ou tente novamente mais tarde.'
      );
    }

    yield put(AuthActions.failure());
  }
}

export function* forgotPasswordValidation({ payload }) {
  try {
    const { doc, code, next } = payload;

    const { data } = yield call(api.post, '/forgot_password/validate', {
      doc,
      code,
    });

    api.defaults.headers.Authorization = `Bearer ${data.token}`;

    yield put(AuthActions.forgotPasswordValidationSuccess());
    next();
  } catch (err) {
    const error = err?.response?.data?.message;

    if (err?.response?.status === 400 && error !== 'Unexpected error.') {
      toast.error(error);
    } else {
      toast.error(
        'Ops... Parece que ocorreu algum erro, confira os dados e/ou tente novamente mais tarde.'
      );
    }

    yield put(AuthActions.failure());
  }
}

export function* updateForgottenPassword({ payload }) {
  try {
    const { confirmPassword, password } = payload;

    yield call(api.post, '/reset_password', {
      confirmPassword,
      password,
    });

    yield put(AuthActions.updateForgottenPasswordSuccess());
    history.push('/user/login');

    toast.success('Senha alterada com sucesso.');
  } catch (err) {
    const error = err?.response?.data?.message;

    if (err?.response?.status === 400 && error !== 'Unexpected error.') {
      toast.error(error);
    } else {
      toast.error(
        'Ops... Parece que ocorreu algum erro, confira os dados e/ou tente novamente mais tarde.'
      );
    }
    yield put(AuthActions.failure());
  }
}

export function setToken({ payload }) {
  if (!payload) {
    return;
  }

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  api.defaults.headers.Authorization = null;
  history.push('/user/login');
}

export function* authFailure({ payload }) {
  const permissionLevel = yield select(
    (state) => state.user.profile?.permissionLevel
  );

  yield put(
    permissionLevel === 'admin'
      ? AuthActions.refreshAdminSignInRequest()
      : AuthActions.refreshSignInRequest()
  );
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/ADMIN_REFRESH_SIGN_IN_REQUEST', refreshAdminSignIn),
  takeLatest('@auth/ADMIN_SIGN_IN_REQUEST', adminSignIn),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/REFRESH_SIGN_IN_REQUEST', refreshSignIn),
  takeLatest('@auth/FORGOT_PASSWORD_REQUEST', forgotPassword),
  takeLatest(
    '@auth/FORGOT_PASSWORD_VALIDATION_REQUEST',
    forgotPasswordValidation
  ),
  takeLatest(
    '@auth/UPDATE_FORGOTTEN_PASSWORD_REQUEST',
    updateForgottenPassword
  ),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/AUTH_FAILURE', authFailure),
]);

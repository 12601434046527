import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@recurrence/CREATE_RECURRENCE_PLAN_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@recurrence/CREATE_RECURRENCE_PLAN_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@recurrence/CREATE_SUBSCRIPTION_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@recurrence/CREATE_SUBSCRIPTION_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@recurrence/UPDATE_SUBSCRIPTION_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@recurrence/UPDATE_SUBSCRIPTION_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@recurrence/UPDATE_SUBSCRIPTION_CARD_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@recurrence/UPDATE_SUBSCRIPTION_CARD_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@recurrence/FAILURE': {
        draft.loading = false;

        break;
      }

      default:
    }
  });

export default reducer;

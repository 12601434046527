import { combineReducers } from 'redux';

import auth from './auth';
import customer from './customer';
import dropInCheckout from './dropInCheckout';
import loginProfiles from './loginProfiles';
import menu from './menu';
import paymentBondsOrders from './paymentBondsOrders';
import paymentLinks from './paymentLinks';
import plans from './plans';
import recurrence from './recurrence';
import settings from './settings';
import transactions from './transactions';
import user from './user';
import webhooks from './webhooks';

const reducers = combineReducers({
  menu,
  settings,
  auth,
  paymentLinks,
  recurrence,
  transactions,
  user,
  paymentBondsOrders,
  dropInCheckout,
  customer,
  webhooks,
  plans,
  loginProfiles,
});

export default reducers;

export function adminGetTransactionsRequest({
  setTransactions,
  startDate,
  endDate,
}) {
  return {
    type: '@transactions/ADMIN_GET_TRANSACTIONS_REQUEST',
    payload: {
      setTransactions,
      startDate,
      endDate,
    },
  };
}

export function adminGetTransactionsSuccess() {
  return {
    type: '@transactions/ADMIN_GET_TRANSACTIONS_SUCCESS',
    payload: {},
  };
}

export function adminUpdateTransactionsRequest({
  transactionId,
  status,
  setTransaction,
}) {
  return {
    type: '@transactions/ADMIN_UPDATE_TRANSACTIONS_REQUEST',
    payload: {
      transactionId,
      status,
      setTransaction,
    },
  };
}

export function adminUpdateTransactionsSuccess() {
  return {
    type: '@transactions/ADMIN_UPDATE_TRANSACTIONS_SUCCESS',
    payload: {},
  };
}

export function getTransactionsRequest({
  setTransactions,
  startDate,
  endDate,
}) {
  return {
    type: '@transactions/GET_TRANSACTIONS_REQUEST',
    payload: {
      setTransactions,
      startDate,
      endDate,
    },
  };
}

export function getTransactionsSuccess() {
  return {
    type: '@transactions/GET_TRANSACTIONS_SUCCESS',
    payload: {},
  };
}

export function createPaymentRequest({
  amount,
  receiverId,
  buyer,
  installments,
  creditCard,
  method,
  paymentLink,
  pixels,
  // sessionID,
}) {
  return {
    type: '@transactions/CREATE_PAYMENT_REQUEST',
    payload: {
      amount,
      receiverId,
      buyer,
      installments,
      creditCard,
      method,
      paymentLink,
      pixels,
      // sessionID,
    },
  };
}

export function createPaymentSuccess() {
  return {
    type: '@transactions/CREATE_PAYMENT_SUCCESS',
    payload: {},
  };
}

export function createPaymentByCheckoutRequest({
  amount,
  buyer,
  installments,
  creditCard,
  method,
  checkout,
  pixels,
  sessionID,
}) {
  return {
    type: '@transactions/CREATE_PAYMENT_BY_CHECKOUT_REQUEST',
    payload: {
      amount,
      buyer,
      installments,
      creditCard,
      method,
      checkout,
      pixels,
      sessionID,
    },
  };
}

export function createPaymentByCheckoutSuccess() {
  return {
    type: '@transactions/CREATE_PAYMENT_BY_CHECKOUT_SUCCESS',
    payload: {},
  };
}

export function getInstallmentsRequest({ value, username, setInstallments }) {
  return {
    type: '@transactions/GET_INSTALLMENTS_REQUEST',
    payload: { value, username, setInstallments },
  };
}

export function getInstallmentsSuccess() {
  return {
    type: '@transactions/GET_INSTALLMENTS_SUCCESS',
    payload: {},
  };
}

export function getTransactionRequest({ setTransaction, transactionId }) {
  return {
    type: '@transactions/GET_TRANSACTION_REQUEST',
    payload: {
      setTransaction,
      transactionId,
    },
  };
}

export function getTransactionSuccess() {
  return {
    type: '@transactions/GET_TRANSACTION_SUCCESS',
    payload: {},
  };
}

export function failure({ status }) {
  return {
    type: '@transactions/FAILURE',
    payload: { status },
  };
}

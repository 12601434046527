export function adminCreateSubscriptionRequest({ planId, userId, status }) {
  return {
    type: '@plans/ADMIN_CREATE_SUBSCRIPTION_REQUEST',
    payload: {
      planId,
      userId,
      status,
    },
  };
}

export function adminCreateSubscriptionSuccess() {
  return {
    type: '@plans/ADMIN_CREATE_SUBSCRIPTION_SUCCESS',
    payload: {},
  };
}

export function adminUpdateSubscriptionRequest({ planId, id, status }) {
  return {
    type: '@plans/ADMIN_UPDATE_SUBSCRIPTION_REQUEST',
    payload: {
      planId,
      id,
      status,
    },
  };
}

export function adminUpdateSubscriptionSuccess() {
  return {
    type: '@plans/ADMIN_UPDATE_SUBSCRIPTION_SUCCESS',
    payload: {},
  };
}

export function failure({ status }) {
  return {
    type: '@plans/FAILURE',
    payload: { status },
  };
}

import { toast } from 'react-toastify';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as Actions from './actions';
import Types from './types';
import AppLocale from '~/lang';
import api from '~/services/api';

export function* createLoginProfile({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];
  const notification = toast.loading(messages['alert.waiting-to-create']);

  try {
    const { name, email, password } = payload;

    yield call(api.post, '/users/login_profiles', {
      name,
      email,
      password,
    });

    toast.update(notification, {
      render: messages['alert.login-profiles.success'],
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.createLoginProfilesSuccess());
  } catch (err) {  
    toast.update(notification, {
      render: messages['alert.default-error-login-profile'],
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.failure({ status: err.response?.status }));
  }
}

export function* deleteLoginProfile({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];
  const notification = toast.loading(messages['alert.waiting-to-delete']);

  try {
    const { id } = payload;

    yield call(api.delete, `/users/login_profiles/${id}`);

    toast.update(notification, {
      render: messages['alert.login-profiles.successfully-deleted'],
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.createLoginProfilesSuccess());
  } catch (err) {
    const errorTag = err.response.data?.tag;

    toast.update(notification, {
      render:
        messages[
          errorTag && errorTag !== '0'
            ? `alert.errors.${errorTag}`
            : 'alert.default-error'
        ],
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.failure({ status: err.response?.status }));
  }
}

export function* failure({ payload }) {
  const status = payload?.status;

  if (status === 401) {
    yield put(AuthActions.authFailure());
  }
}

export default all([
  takeLatest(Types.CREATE_LOGIN_PROFILE_REQUEST, createLoginProfile),
  takeLatest(Types.DELETE_LOGIN_PROFILE_REQUEST, deleteLoginProfile),
  takeLatest(Types.FAILURE, failure),
]);

import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as Actions from './actions';
import api from '~/services/api';

export function* createWebhook({ payload }) {
  const notification = toast.loading('Cadastrando webhook...');
  try {
    const { url, type } = payload;

    yield call(api.post, '/webhooks', {
      url,
      type,
    });

    yield put(Actions.createWebhookSuccess());
    toast.update(notification, {
      render: 'Webhook cadastrado com sucesso.',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
  } catch (err) {
    toast.update(notification, {
      render:
        'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.failure());
  }
}

export function* deleteWebhook({ payload }) {
  const notification = toast.loading('Removendo webhook...');
  try {
    const { id } = payload;

    yield call(api.delete, `/webhooks/${id}`);

    yield put(Actions.deleteWebhookSuccess());

    toast.update(notification, {
      render: 'Webhook deletado com sucesso.',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
  } catch (err) {
    toast.update(notification, {
      render:
        'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.failure());
  }
}

export function* failure({ payload }) {
  const { status } = payload;

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export default all([
  takeLatest('@webhook/CREATE_WEBHOOK_REQUEST', createWebhook),
  takeLatest('@webhook/DELETE_WEBHOOK_REQUEST', deleteWebhook),
  takeLatest('@webhook/FAILURE', failure),
]);

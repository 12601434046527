import axios from 'axios';

const api = axios.create({
  baseURL: `https://api.zoop.ws/v1/marketplaces/${process.env.REACT_APP_ZOOP_MARKETPLACE}`,
  auth: {
    username: process.env.REACT_APP_ZOOP_KEY_ZPK,
    password: '',
  },
});

export async function tokenizeCard({ cvc, expiry, name, number }) {
  const month = expiry.slice(0, 2);
  const year = expiry.slice(2);

  try {
    const { data } = await api.post(
      '/cards/tokens',
      {
        holder_name: name,
        expiration_month: month,
        expiration_year: year,
        card_number: number,
        security_code: cvc,
      },
      {
        auth: {
          username: process.env.REACT_APP_ZOOP_KEY_ZPK,
          password: '',
        },
      }
    );

    return data;
  } catch (err) {
    return null;
  }
}

export async function tokenizeBank({
  holderName,
  accountNumber,
  routingNumber,
  bankCode,
  taxpayerId,
  type,
}) {
  try {
    const doc =
      taxpayerId.length === 11
        ? {
          taxpayer_id: taxpayerId,
        }
        : {
          ein: taxpayerId,
        };

    const { data } = await api.post('/bank_accounts/tokens', {
      holder_name: holderName,
      bank_code: bankCode,
      routing_number: routingNumber,
      account_number: accountNumber,
      ...doc,
      type,
    });

    return data;
  } catch (err) {
    return null;
  }
}

import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@transactions/ADMIN_GET_TRANSACTIONS_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@transactions/ADMIN_GET_TRANSACTIONS_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@transactions/GET_TRANSACTIONS_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@transactions/GET_TRANSACTIONS_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@transactions/CREATE_PAYMENT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@transactions/CREATE_PAYMENT_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@transactions/CREATE_PAYMENT_BY_CHECKOUT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@transactions/CREATE_PAYMENT_BY_CHECKOUT_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@transactions/ADMIN_UPDATE_TRANSACTIONS_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@transactions/ADMIN_UPDATE_TRANSACTIONS_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@transactions/FAILURE': {
        draft.loading = false;

        break;
      }

      default:
    }
  });

export default reducer;

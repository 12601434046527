import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as Actions from './actions';
import api from '~/services/api';
import history from '~/services/history';

export function* cancelOrder({ payload }) {
  try {
    const { orderId } = payload;

    yield call(api.post, `/public/payment_bonds_orders/cancel`, null, {
      params: {
        orderId,
      },
    });

    yield put(Actions.cancelOrderSuccess());
    toast.success('Borderô cancelado com sucesso.');
  } catch (err) {
    toast.error('Erro ao cancelar o borderô. Borderô pode não ser cancelável.');
    yield put(Actions.failure({ status: err?.response?.status }));
  }
}

export function* createOrder({ payload }) {
  try {
    const { installments, creditCard, method, bonds, localStorageCartKey } =
      payload;

    const { data } = yield call(api.post, '/public/payment_bonds_orders/pay', {
      installments,
      method,
      bonds,
      card: creditCard,
    });

    yield put(Actions.createOrderSuccess());

    if (data.status === 'failed') {
      toast.error('Erro ao realizar transação.');
    } else {
      toast.success('Transação realizada com sucesso.');
      localStorage.setItem(localStorageCartKey, JSON.stringify([]));
      history.push({
        pathname: '#/checkout/succeeded',
        state: { transaction: data, method, initialRoute: '/payment-bonds' },
      });
    }
  } catch (err) {
    toast.error('Erro ao realizar transação.');
    yield put(Actions.failure({ status: err?.response?.status }));
  }
}

export default all([
  takeLatest('@paymentBondsOrders/CANCEL_ORDER_REQUEST', cancelOrder),
  takeLatest('@paymentBondsOrders/CREATE_ORDER_REQUEST', createOrder),
]);

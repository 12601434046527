import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import customer from './customer/sagas';
import dropInCheckout from './dropInCheckout/sagas';
import loginProfiles from './loginProfiles/sagas';
import paymentBondsOrders from './paymentBondsOrders/sagas';
import paymentLinks from './paymentLinks/sagas';
import plans from './plans/sagas';
import recurrence from './recurrence/sagas';
import transactions from './transactions/sagas';
import user from './user/sagas';
import webhooks from './webhooks/sagas';

export default function* rootSaga() {
  yield all([
    auth,
    transactions,
    user,
    paymentLinks,
    recurrence,
    paymentBondsOrders,
    dropInCheckout,
    customer,
    webhooks,
    plans,
    loginProfiles,
  ]);
}

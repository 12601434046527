export function cancelOrderRequest({ orderId }) {
  return {
    type: '@paymentBondsOrders/CANCEL_ORDER_REQUEST',
    payload: {
      orderId,
    },
  };
}

export function cancelOrderSuccess() {
  return {
    type: '@paymentBondsOrders/CANCEL_ORDER_SUCCESS',
    payload: {},
  };
}

export function createOrderRequest({
  bonds,
  installments,
  creditCard,
  method,
  localStorageCartKey,
}) {
  return {
    type: '@paymentBondsOrders/CREATE_ORDER_REQUEST',
    payload: {
      bonds,
      installments,
      creditCard,
      method,
      localStorageCartKey,
    },
  };
}

export function createOrderSuccess() {
  return {
    type: '@paymentBondsOrders/CREATE_ORDER_SUCCESS',
    payload: {},
  };
}

export function failure({ status }) {
  return {
    type: '@paymentBondsOrders/FAILURE',
    payload: { status },
  };
}

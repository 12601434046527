/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © Todos los derechos reservados.',
  'general.safe-environment': 'Ambiente criptografado e 100% seguro',

  'notification.empty': 'Sem notificações',
  'notification.pending-account':
    'Atenção! Para liberar sua conta, é necessário enviar os documentos.',

  /* 02.SignUp, SignIn */
  'user.login-title': 'Bem-vindo! Faça login aqui:',
  'user.register': 'Registrar',
  'user.forgot-password': 'Esqueceu sua senha',
  'user.email': 'Email',
  'user.email.placeholder': 'usuario@switchpay.com.br',
  'user.password': 'Senha',
  'user.password.placeholder': 'Digite sua senha',
  'user.doc': 'CPF/CNPJ',
  'user.doc.placeholder': 'CPF ou CNPJ',
  'user.forgot-password-question': 'Esqueceu a senha?',
  'user.fullname': 'Nome completo',
  'user.login-button': 'ENTRAR',
  'user.register-button': 'REGISTRAR',
  'user.reset-password-button': 'REDEFINIR',
  'user.username': 'Usuário',
  'user.username.placeholder': 'Nome de Usuário',
  'user.owner': 'Digite os dados do responsável',
  'user.personal-data': 'Dados pessoais',
  'user.address': 'Endereço',
  'user.business': 'Tipo de conta',
  'user.business.address': 'Endereço da empresa',
  'user.credentials': 'Credenciais de acesso',
  'user.first-name': 'Primeiro nome',
  'user.first-name.placeholder': 'Digite seu primeiro nome',
  'user.last-name': 'Último nome',
  'user.last-name.placeholder': 'Digite seu último nome',
  'user.owner.doc': 'CPF',
  'user.phone': 'Telefone',
  'user.date-birth': 'Data de nascimento',
  'user.address.post-code': 'CEP',
  'user.address.post-code.placeholder': '00000000',
  'user.address.state': 'Estado',
  'user.address.street': 'Endereço',
  'user.address.city': 'Cidade',
  'user.address.neighborhood': 'Bairro',
  'user.address.number': 'Número',
  'user.address.complement': 'Complemento (Opcional)',
  'user.statement-descriptor': 'Nome fantasia',
  'user.statement-descriptor.placeholder': 'Nome impresso na Fatura',
  'user.business.name': 'Nome da empresa',
  'user.business.name.placeholder': 'Minha empresa LTDA',
  'user.business.doc': 'CNPJ',
  'user.business.doc.placeholder': '00.000.000/0000-00',
  'user.business.type-pf': 'Pessoa Física',
  'user.business.type-pj': 'Pessoa Jurídica',
  'user.business.mcc': 'Categoria do negócio',
  'user.business.mcc.placeholder': 'Selecione a categoria',
  'user.business.email': 'Email comercial',
  'user.business.phone': 'Telefone comercial',
  'user.business.opening-date': 'Data de abertura',
  'user.business.statement-descriptor': 'Nome fantasia',
  'user.license-1': 'Concordo com os ',
  'user.license-2': ' e a ',
  'user.license.terms-of-use': 'termos de uso',
  'user.license.privacy-policy': 'política de privacidade',

  'form.name': 'Nome',
  'form.email': 'Email',
  'form.password': 'Senha',
  'form.validations.name': 'É necessário informar um nome',
  'form.validations.email': 'É necessário informar um email válido',
  'form.validations.password': 'É necessário informe uma senha válida',
  'form.validations.password.min': 'A senha deve conter no mínimo 6 caracteres',

  'user.form.validations.first-name': 'É necessário informar o primeiro nome',
  'user.form.validations.last-name': 'É necessário informar o último nome',
  'user.form.validations.owner.doc': 'É necessário informar um CPF',
  'user.form.validations.email': 'É necessário informar um email válido',
  'user.form.validations.phone': 'É necessário informar um telefone',
  'user.form.validations.date-birth':
    'É necessário informar uma data de nascimento',
  'user.form.validations.date-birth.max': 'É necessário possuir 18 anos',
  'user.form.validations.date': 'Digite uma data válida',
  'user.form.validations.address.post-code': 'É necessário informar um CEP',
  'user.form.validations.address.state': 'É necessário informar um estado',
  'user.form.validations.address.street': 'É necessário informar um endereço',
  'user.form.validations.address.city': 'É necessário informar uma cidade',
  'user.form.validations.address.neighborhood':
    'É necessário informar um bairro',
  'user.form.validations.address.number': 'É necessário informar um número',
  'user.form.validations.statement-descriptor':
    'É necessário informar o nome fantasia',
  'user.form.validations.business.name': 'É necessário informar um nome',
  'user.form.validations.business.mcc':
    'É necessário informar a categoria do negócio',
  'user.form.validations.business.doc': 'É necessário informar um CNPJ',
  'user.form.validations.business.opening-date':
    'É necessário informar a data de abertura',
  'user.form.validations.license':
    'É necessário aceitar os termos de uso e a política de privacidade',
  'user.form.validations.username': 'É necessário informar um usuário',
  'user.form.validations.doc': 'Informe um valor válido para o CPF ou CNPJ',
  'user.form.validations.doc.min': 'Deve conter no minímo 11 dígitos',
  'user.form.validations.doc.max': 'Deve conter no máximo 14 dígitos',
  'user.form.validations.password': 'Informe uma senha válida',
  'user.form.validations.password.min':
    'A senha deve conter no mínimo 6 caracteres',
  'user.form.validations.oldPassword': 'Digite sua senha antiga',
  'user.form.validations.newPassword': 'Digite sua senha nova',
  'user.form.validations.newPassword.confirm': 'As senhas devem ser iguais',

  /* 03.Menu */
  'menu.home': 'Início',
  'menu.transactions': 'Transações',
  'menu.details': 'Detalhes',
  'menu.qrcode': 'Meu QrCode',
  'menu.payment-links': 'Links',
  'menu.conciliation': 'Conciliação',
  'menu.app': 'Início',
  'menu.dashboard': 'Painel',
  'menu.users': 'Usuários',
  'menu.start': 'Início',
  'menu.sales': 'Vendas',
  'menu.receivements': 'Recebimentos',
  'menu.settings': 'Configurações',
  'menu.account': 'Conta',
  'menu.submit-document': 'Envio de documento',
  'menu.checkouts': 'DropShipping',
  'menu.personalize': 'Personalizar',
  'menu.create': 'Criar',
  'menu.payment-bonds': 'Títulos',
  'menu.orders': 'Borderôs',
  'menu.show': 'Mostrar',
  'menu.customers': 'Clientes',
  'menu.preferences': 'Preferências',
  'menu.plans': 'Planos',
  'menu.recurrence': 'Recorrência',
  'menu.subscriptions': 'Assinaturas',
  'menu.webhooks': 'Webhooks',
  'menu.splits': 'Divisões',
  'menu.login-profiles': 'Perfis de login',
  'topnav.support': 'Suporte',
  'topnav.logout': 'Sair',

  /* 04.Error Pages */
  'pages.error-title': 'Ops, parece que ocorreu um erro!',
  'pages.error-code': 'Código de erro',
  'pages.go-back-home': 'VOLTAR PARA O INÍCIO',

  /* 05.Common */
  'common.date-range': 'Período',
  'common.date-range-today': 'Hoje',
  'common.date-range-last-7-days': 'Últimos 7 dias',
  'common.date-range-last-15-days': 'Últimos 15 dias',
  'common.date-range-last-30-days': 'Últimos 30 dias',
  'common.date-range-last-60-days': 'Últimos 60 dias',
  'common.date-range-all-time': 'Todo Período',
  'common.date-now': 'Agora',
  'common.start-date': 'Data Inicial',
  'common.end-date': 'Data Final',
  'common.all': 'Tudo',
  'common.edit': 'Editar',
  'common.cancel': 'Cancelar',
  'common.update': 'Atualizar',
  'common.save': 'Salvar',
  'common.delete': 'Excluir',
  'common.copied-link': 'Link copiado!',
  'common.amount': 'Valor',
  'common.gross-amount': 'Valor bruto',
  'common.net-amount': 'Valor líquido',
  'common.creation-date': 'Data de criação',
  'common.fee': 'Taxa',
  'common.actions': 'Ações',
  'common.details': 'Detalhes',
  'common.plan': 'Plano',
  'common.subscription': 'Assinatura',
  'common.features': 'Funcionalidades',
  'common.none': 'Nenhum',
  'common.status': 'Status',
  'common.yes': 'Sim',
  'common.no': 'Não',
  'common.delete.message':
    'Após apagar, a ação não poderá ser desfeita. Deseja mesmo apagar?',

  /* 06.Data */
  'data.user.name': 'Nome',
  'data.user.doc': 'CPF/CNPJ',
  'data.user.status.released': 'Liberado',
  'data.user.status.blocked': 'Bloqueado',
  'data.user.status.pending': 'Pendente',
  'data.user.email': 'Email',
  'data.user.phone': 'Telefone',
  'data.user.dateBirth': 'Data de nascimento',
  'data.user.address': 'Endereço',
  'data.user.Number': 'Número',
  'data.user.Complement': 'Complemento',
  'data.user.neighborhood': 'Bairro',
  'data.user.city': 'Cidade',
  'data.user.state': 'Estado',
  'data.user.postCode': 'Cep',
  'data.user.currentPassword': 'Senha atual',
  'data.user.newPassword': 'Nova senha',
  'data.user.confirmNewPassword': 'Confirme a nova senha',
  'data.user.avatar': 'Selecione seu avatar',
  'data.user.type': 'Tipo de conta',
  'data.user.type.seller': 'Vendedor',
  'data.user.type.buyer': 'Comprador',

  'data.customer.name': 'Nome',
  'data.customer.doc': 'CPF/CNPJ',
  'data.customer.email': 'Email',
  'data.customer.phone': 'Telefone',
  'data.customer.dateBirth': 'Data de nascimento',
  'data.customer.address': 'Endereço',
  'data.customer.Number': 'Número',
  'data.customer.Complement': 'Complemento',
  'data.customer.neighborhood': 'Bairro',
  'data.customer.city': 'Cidade',
  'data.customer.state': 'Estado',
  'data.customer.postCode': 'Cep',

  'data.payment-type': 'Forma de pagamento',
  'data.payment-type.credit': 'Cartão de crédito',
  'data.payment-type.boleto': 'Boleto bancário',
  'data.payment-type.PIX': 'Pix',
  'data.payment-type.TED': 'TED',

  'data.transaction.status.pending': 'Pendente',
  'data.transaction.status.succeeded': 'Aprovada',
  'data.transaction.status.failed': 'Falhada',
  'data.transaction.status.canceled': 'Cancelada',
  'data.transaction.status.canceled_security_risk': 'Cancelada',
  'data.transaction.status.charge_back': 'ChargeBack',
  'data.transaction.status.dispute': 'Em disputa',
  'data.transaction.type-payment': 'pagamento',
  'data.transaction.type-transfer': 'transferência',
  'data.transaction.type-deposit': 'depósito',
  'data.transaction.type-withdraw': 'retirada',
  'data.transaction.type-charge': 'cobrança',
  'data.transaction.history.operation-created': 'criada',
  'data.transaction.history.operation-proposal': 'proposta',
  'data.transaction.history.operation-authorization': 'autorizada',
  'data.transaction.history.operation-void': 'estornado',
  'data.transaction.history.operation-paid': 'pago',

  'data.transaction.table-status': 'Status',
  'data.transaction.table-transactionId': 'Id da transação',
  'data.transaction.table-chargeId': 'Id da cobrança',
  'data.transaction.table-type': 'Tipo',
  'data.transaction.table-customer': 'Cliente',
  'data.transaction.table-method': 'Forma de pagamento',
  'data.transaction.table-amount': 'Valor',
  'data.transaction.table-actions': 'Ações',
  'data.transaction.table-actions.details': 'Detalhes',
  'data.transaction.table-creationDate': 'Data da transação',

  'data.receivable.installment': 'Parcela',
  'data.receivable.status': 'Status do pagamento',
  'data.receivable.status.pending': 'Pendente',
  'data.receivable.status.paid': 'Pago',
  'data.receivable.status.canceled': 'Cancelado',
  'data.receivable.status.refunded': 'Reembolsado',
  'data.receivable.expected-date': 'Data do recebimento',
  'data.receivable.gross-amount': 'Valor bruto',
  'data.receivable.amount': 'Valor líquido',
  'data.receivable.fee': 'Taxa',
  'data.receivable.anticipation-fee': 'Taxa de antecipação',

  'data.payment-links.status.active': 'Ativo',
  'data.payment-links.status.inactive': 'Inativo',

  'data.recurrence-plans.id': 'Id do plano',
  'data.recurrence-plans.name': 'Nome',
  'data.recurrence-plans.description': 'Descrição',
  'data.recurrence-plans.amount': 'Valor',
  'data.recurrence-plans.status': 'Status',
  'data.recurrence-plans.status.active': 'Ativo',
  'data.recurrence-plans.status.inactive': 'Inativo',
  'data.recurrence-plans.billing-type': 'Tipo de cobrança',
  'data.recurrence-plans.billing-type.prepaid': 'Pré-pago',
  'data.recurrence-plans.billing-type.postpaid': 'Pós-pago',
  'data.recurrence-plans.interval': 'Tipo de intervalo',
  'data.recurrence-plans.interval.weekly': 'Semanal',
  'data.recurrence-plans.interval.monthly': 'Mensal',
  'data.recurrence-plans.interval.annually': 'Anual',
  'data.recurrence-plans.frequency.weekly': 'por semana',
  'data.recurrence-plans.frequency.monthly': 'por mês',
  'data.recurrence-plans.frequency.annually': 'por ano',
  'data.recurrence-plans.interval-count': 'Intervalo de cobrança',
  'data.recurrence-plans.trial-period-days': 'Período gratuito (dias)',

  'data.subscriptions.status': 'Status',
  'data.subscriptions.status.active': 'Ativa',
  'data.subscriptions.status.pending': 'Pendente',
  'data.subscriptions.status.suspended': 'Suspensa',
  'data.subscriptions.status.canceled': 'Cancelada',
  'data.subscriptions.table-status': 'Status',
  'data.subscriptions.table-cycle': 'Cobranças emitidas',
  'data.subscriptions.table-start-at': 'Início das cobranças',
  'data.subscriptions.table-last-billing': 'Última cobrança',
  'data.subscriptions.table-next-billing': 'Próxima cobrança',
  'data.subscriptions.table-plan': 'Plano',
  'data.subscriptions.table-customer': 'Cliente',
  'data.subscriptions.table-details': 'Detalhes',

  'data.plan.feature.recurrence': 'Recorrência',
  'data.plan.feature.paymentBonds': 'Títulos de pagamento',
  'data.plan.feature.split': 'Split',

  'data.split.recipient': 'Destinatário',
  'data.split.type': 'Tipo',
  'data.split.type.percentage': 'Porcentagem',
  'data.split.type.fixed': 'Fixo',

  'data.table-actions': 'Ações',
  'data.table-actions.details': 'Detalhes',
  'data.table-actions.delete': 'Deletar',

  'data.sale.status-pending': 'pendente',
  'data.sale.status-succeeded': 'aprovada',
  'data.sale.status-failed': 'falhada',
  'data.sale.status-canceled': 'cancelada',
  'data.sale.status-charge_back': 'chargeBack',
  'data.sale.status-dispute': 'em disputa',
  'data.sale.method-credit.short': 'crédito',
  'data.sale.method-boleto.short': 'boleto',
  'data.sale.method-PIX.short': 'PIX',
  'data.sale.method-TED.short': 'TED',

  'data.receivement.status-concilied': 'conciliado',
  'data.receivement.status-pending': 'pendente',
  'data.receivement.status-inconsistency': 'Divergência de pagamento',

  /* 07.Buttons */
  'button.add': 'Adicionar',
  'button.form.next': 'Avançar',
  'button.form.previous': 'Voltar',
  'button.form.filter': 'Filtrar',
  'button.form.search': 'Buscar',
  'button.form.create': 'Criar',
  'button.form.update': 'Atualizar',
  'button.form.delete': 'Apagar',
  'button.filter.clear': 'Limpar filtros',
  'button.form.edit': 'Editar',
  'button.form.register': 'Cadastrar',
  'button.form.pay': 'Pagar',

  /* 08.Forms */
  'form.payment.full-name': 'Razão Social/Nome completo',
  'form.payment.first-name': 'Primeiro nome',
  'form.payment.last-name': 'Último nome',
  'form.payment.email': 'Email',
  'form.payment.doc': 'CNPJ/CPF',
  'form.payment.phone': 'Telefone',
  'form.payment.date-birth': 'Data de nascimento',
  'form.payment.address.post-code': 'CEP',
  'form.payment.address.state': 'Estado',
  'form.payment.address.city': 'Cidade',
  'form.payment.address.complement': 'Complemento (Opcional)',
  'form.payment.address.neighborhood': 'Bairro',
  'form.payment.address.street': 'Endereço',
  'form.payment.address.number': 'Número',
  'form.payment.card-number': 'Número do cartão',
  'form.payment.card-holder-name': 'Nome do titular',
  'form.payment.card-expiration-date': 'Data de validade',
  'form.payment.card-expiration-date.month': 'Mês',
  'form.payment.card-expiration-date.year': 'Ano',
  'form.payment.card-security-code': 'Código de segurança',
  'form.payment.installment': 'Parcelamento',

  'form.filter': 'Filtrar',
  'form.date-filter': 'Filtrar por data',
  'form.date-filter.start-date': 'Data inicial',
  'form.date-filter.end-date': 'Data final',

  /* 09.Alert messages */
  // default messages
  'alert.waiting-to-create': 'Cadastrando...',
  'alert.waiting-to-delete': 'Removendo...',
  'alert.subscription.success': 'Assinatura realizada com sucesso.',
  'alert.subscription.changed-card.success': 'Cartão alterado com sucesso.',
  'alert.subscription.changed-status':
    'Status da assinatura alterado com sucesso.',
  'alert.payment.success': 'Transação realizada com sucesso.',
  'alert.login-profiles.successfully-created': 'Perfil criado com sucesso.',
  'alert.login-profiles.successfully-deleted': 'Perfil removido com sucesso.',
  'alert.subscription.default-error':
    'Ops... Parece que ocorreu um erro ao realizar a assinatura.',
  'alert.payment.default-error':
    'Ops... Parece que ocorreu um erro ao realizar transação. Verifique seus dados e tente novamente.',
  'alert.default-error':
    'Ops... Parece que ocorreu algum erro. Verifique os dados e/ou tente novamente mais tarde.',

  // specific errors
  'alert.default-error-login-profile':
    'O seu usuario deve ser PRIMARIO para inserir novos logins',
  // system
  'alert.errors.system_error':
    'Ops... Parece que ocorreu um erro ao processar o pagamento, aguarde e tente novamente mais tarde.',

  // subscription
  'alert.errors.subscription_cannot_be_canceled':
    'Ops... Parece que a assinatura não pode ser cancelada.',
  'alert.errors.subscription_card_could_not_be_updated':
    'Ops... Parece que o cartão não pôde ser atualizado.',

  // login profiles
  'alert.errors.not_allowed_remove_primary':
    'Não é possível remover o perfil primário.',
  'alert.errors.login_profile_not_found': 'Perfil não encontrado.',
  'alert.errors.not_allowed_to_change':
    'Ops... Parece que você não tem permissão para fazer essa alteração.',

  // transaction
  'alert.errors.unauthorized': 'Transação não autorizada.',
  'alert.errors.unauthorized_by_restriction':
    'Transação não autorizada. O cartão informado possui alguma restrição.',
  'alert.errors.denied_by_provider': 'Transação negada pelo provedor.',
  'alert.errors.authorization_error':
    'Ops... Parece que ocorreu um erro de autorização.',
  'alert.errors.expired_card': 'O cartão informado está expirado.',
  'alert.errors.invalid_card':
    'O cartão informado é inválido ou possui alguma restrição.',
  'alert.errors.incorrect_security_code':
    'O código de segurança do cartão está incorreto.',
  'alert.errors.invalid_card_number': 'O número do cartão é inválido.',
  'alert.errors.restricted_card': 'O cartão informado possui alguma restrição.',
  'alert.errors.invalid_card_date': 'O cartão informado está vencido.',

  /* 10.Pages */
  'pages.recover-password.doc': 'Digite seu CPF ou CNPJ',
  'pages.recover-password.code': 'Digite seu código de verificação',
  'pages.recover-password.code-message':
    'Um código de verificação foi enviado para seu telefone para validarmos a sua identidade.',
  'pages.recover-password.password': 'Senha',
  'pages.recover-password.confirm-password': 'Confirmar senha',

  'pages.users.search-label': 'Buscar pelo usuário',
  'pages.users.doc-label': 'Buscar pelo CPF/CNPJ',
  'pages.users.search-placeholder': 'Digite o nome ou username do usuário',
  'pages.users.doc-placeholder': 'Digite o CPF ou CNPJ do usuário',
  'pages.users.export-to-csv': 'Exportar para Excel',
  'pages.users.registered-users': 'Usuários cadastrados',

  'pages.users.date-range': 'Período',
  'pages.users.date-range-today': 'Hoje',
  'pages.users.date-range-last-7-days': 'Últimos 7 dias',
  'pages.users.date-range-last-15-days': 'Últimos 15 dias',
  'pages.users.date-range-last-30-days': 'Últimos 30 dias',
  'pages.users.date-range-last-60-days': 'Últimos 60 dias',

  'pages.users.table-status': 'Status',
  'pages.users.table-customer': 'Cliente',
  'pages.users.table-doc': 'CPF/CNPJ',
  'pages.users.table-email': 'E-mail',
  'pages.users.table-username': 'Nome de usuário',
  'pages.users.table-created-in': 'Criação',
  'pages.users.table-actions': 'Ações',
  'pages.users.table-actions.details': 'Detalhes',
  'pages.users.no-users': 'Não há usuários para o termo procurado.',
  'pages.users.custom-filter': 'Filtro Personalizado',
  'pages.users.status.all': 'Todos',
  'pages.users.status': 'Selecione um status',
  'pages.users.type': 'Selecione um tipo',
  'pages.users.start-date-label': 'Data de início',
  'pages.users.end-date-label': 'Data de término',

  'pages.user-details.no-subscription': 'Não há assinatura para este usuário.',
  'pages.user-details.form.plan': 'Selecione um plano',
  'pages.user-details.form.validations.plan': 'É necessário informar um plano',
  'pages.user-details.form.validations.status':
    'É necessário informar um status',

  'pages.transaction.custom-filter': 'Filtro Personalizado',
  'pages.transaction.status': 'Selecione um status',
  'pages.transaction.status.all': 'Todos',
  'pages.transaction.seller': 'Selecione um vendedor',
  'pages.transaction.seller.all': 'Todos',
  'pages.transaction.date-range': 'Período',
  'pages.transaction.date-range-today': 'Hoje',
  'pages.transaction.date-range-last-7-days': 'Últimos 7 dias',
  'pages.transaction.date-range-last-15-days': 'Últimos 15 dias',
  'pages.transaction.date-range-last-30-days': 'Últimos 30 dias',
  'pages.transaction.date-range-last-60-days': 'Últimos 60 dias',
  'pages.transaction.search-label': 'Buscar pelo id da transacao',
  'pages.transaction.search-placeholder': 'Digite o id da transacao',
  'pages.transaction.start-date-label': 'Data de início',
  'pages.transaction.end-date-label': 'Data de término',
  'pages.transaction.form.start-date': 'É necessário informar a data de início',
  'pages.transaction.form.end-date': 'É necessário informar a data de término',

  'pages.transaction.sales-history': 'Histórico de Vendas',
  'pages.transaction.export-to-csv': 'Exportar para Excel',
  'pages.transaction.no-transactions':
    'Não há transações no período selecionado.',

  'pages.transaction-details.sales-history': 'Histórico de transação',
  'pages.transaction-details.sales-history.date': 'DATA',
  'pages.transaction-details.sales-history.status': 'STATUS',
  'pages.transaction-details.sales-history.amount': 'VALOR',
  'pages.transaction-details.sales-history.current-status': 'STATUS ATUAL',
  'pages.transaction-details.2nd-copy': '2ª via',

  'pages.transaction-details.customer-details': 'Detalhes do cliente',
  'pages.transaction-details.transaction-details': 'Detalhes da transação',
  'pages.transaction-details.transactionID': 'TRANSAÇÃO ID',
  'pages.transaction-details.totalAmount': 'VALOR',
  'pages.transaction-details.amount': 'Valor capturado',
  'pages.transaction-details.captured-amount': 'Valor capturado',
  'pages.transaction-details.net-amount': 'Valor líquido',
  'pages.transaction-details.fee': 'Tarifas',
  'pages.transaction-details.fee.processing': 'Processando',
  'pages.transaction-details.card-holder-name': 'Portador do cartão',
  'pages.transaction-details.card-number': 'N°Cartão',
  'pages.transaction-details.card-brand': 'Operadora do cartão',
  'pages.transaction-details.transaction-id': 'Id de transação',
  'pages.transaction-details.charge-id': 'Id da cobrança',
  'pages.transaction-details.nsu': 'NSU',
  'pages.transaction-details.capture-method': 'Método de captura',
  'pages.transaction-details.provider': 'Operadora',
  'pages.transaction-details.form-of-payment': 'Forma de pagamento',
  'pages.transaction-details.installments': 'PARCELADO EM ',
  'pages.transaction-details.not-found':
    'A transação que você está buscando não existe e/ou está indisponível no momento.',

  'pages.transaction-details.receivable': 'Parcelas',

  'pages.payment-links.link-history': 'Histórico de Links',
  'pages.payment-links.search-date-range': 'Buscar por período',
  'pages.payment-links.search-name': 'Buscar pelo nome do link',
  'pages.payment-links.search-name-placeholder': 'Digite o nome do link',
  'pages.payment-links.start-date-label': 'De',
  'pages.payment-links.end-date-label': 'Até',
  'pages.payment-links.status': 'Status do link',
  'pages.payment-links.status.all': 'Todos',
  'pages.payment-links.status.active': 'Ativo',
  'pages.payment-links.status.inactive': 'Inativo',
  'pages.payment-links.search-label': 'Buscar pelo nome do link',
  'pages.payment-links.create-payment-link': 'Criar link de pagamento',
  'pages.payment-links.table-status': 'Status',
  'pages.payment-links.table-methods-accepted': 'Métodos aceitos',
  'pages.payment-links.table-name': 'Nome',
  'pages.payment-links.table-link': 'Link',
  'pages.payment-links.table-due-date': 'Vencimento',
  'pages.payment-links.table-amount': 'Valor',
  'pages.payment-links.table-actions': 'Ações',
  'pages.payment-links.table-actions.details': 'Detalhes',
  'pages.payment-links.no-payment-link':
    'Não há links de pagamento para a busca e/ou período selecionado.',
  'pages.payment-links.charge-customer-processing-fee':
    'Cobrar taxa de processamento do cliente',

  'pages.payment-links.new-payment-link.step-data': 'Dados básicos',
  'pages.payment-links.new-payment-link.step-config': 'Configurações',
  'pages.payment-links.new-payment-link.step-verify': 'Verificar dados',
  'pages.payment-links.new-payment-link.step-receivements':
    'Informações de pagamento',
  'pages.payment-links.new-payment-link.methods': 'Métodos de pagamento',
  'pages.payment-links.new-payment-link.form.name': 'NOME DO LINK',
  'pages.payment-links.new-payment-link.form.name.placeholder':
    'Ex. Camisa polo',
  'pages.payment-links.new-payment-link.form.discounts':
    'VALOR DO DESCONTO (%)',
  'pages.payment-links.new-payment-link.form.amount': 'VALOR DA COBRANÇA',
  'pages.payment-links.new-payment-link.form.amount.placeholder': 'R$ 0.00',
  'pages.payment-links.new-payment-link.form.shipping-fee':
    'VALOR FIXO DO FRETE (Opcional)',
  'pages.payment-links.new-payment-link.form.shipping-fee.placeholder':
    'R$ 0.00',
  'pages.payment-links.new-payment-link.form.product':
    'DESCRICÃO DO PRODUTO OU SERVIÇO',
  'pages.payment-links.new-payment-link.form.product.placeholder':
    'Descreva o que você está cobrando',
  'pages.payment-links.new-payment-link.form.file':
    'SELECIONE UMA IMAGEM DO PRODUTO (Opcional)',
  'pages.payment-links.new-payment-link.form.seller-name':
    'Nome vendedor/loja (Opcional)',
  'pages.payment-links.new-payment-link.form.pixel-facebook':
    'PIXEL FACEBOOK (Opcional)',
  'pages.payment-links.new-payment-link.form.days-to-expiration':
    'DIAS PARA EXPIRAÇÃO DO LINK (OPCIONAL)',
  'pages.payment-links.new-payment-link.form.days-to-expiration.placeholder':
    'Quantos dias seu link ficara ativo?',
  'pages.payment-links.new-payment-link.boleto': 'Boleto',
  'pages.payment-links.new-payment-link.credit': 'Cartão de crédito',
  'pages.payment-links.new-payment-link.pix': 'PIX',
  'pages.payment-links.new-payment-link.form.expires-in':
    'DIAS PARA PAGAMENTO DO BOLETO',
  'pages.payment-links.new-payment-link.form.expires-in.placeholder':
    'Dias para vencimento após emitir o boleto',
  'pages.payment-links.new-payment-link.form.installment': 'PARCELAMENTO',
  'pages.payment-links.new-payment-link.form.charge-customer-processing-fee':
    'Cobrar taxa de processamento do cliente',

  'pages.payment-links.new-payment-link.confirm': 'Confirmar dados do link',
  'pages.payment-links.new-payment-link.confirm-name': 'Nome',
  'pages.payment-links.new-payment-link.confirm-amount': 'Valor',
  'pages.payment-links.new-payment-link.confirm-discounts': 'Desconto',
  'pages.payment-links.new-payment-link.confirm-shipping-fee': 'Valor do frete',
  'pages.payment-links.new-payment-link.confirm-description': 'Descrição',
  'pages.payment-links.new-payment-link.confirm-seller-name':
    'Nome vendedor/loja',
  'pages.payment-links.new-payment-link.confirm-pixel-facebook':
    'PIXEL FACEBOOK',
  'pages.payment-links.new-payment-link.confirm-image': 'Foto do produto',
  'pages.payment-links.new-payment-link.confirm-expiration-date':
    'Data de expiração',
  'pages.payment-links.new-payment-link.confirm-payment-methods':
    'Métodos de pagamento',
  'pages.payment-links.new-payment-link.confirm-expires-in':
    'Dias para vencimento após a emissão',
  'pages.payment-links.new-payment-link.confirm-installment': 'Parcelamento',
  'pages.payment-links.new-payment-link.uninformed': 'Não informado',
  'pages.payment-links.new-payment-link.form.validations.discounts':
    'É necessário informar um valor válido',
  'pages.payment-links.new-payment-link.form.validations.amount':
    'É necessário informar um valor válido',
  'pages.payment-links.new-payment-link.form.validations.amount.min':
    'O valor mínimo é R$ 3,00',
  'pages.payment-links.new-payment-link.form.validations.shippingFee':
    'É necessário informar um valor válido para o frete',
  'pages.payment-links.new-payment-link.form.validations.shippingFee.min':
    'É necessário informar um valor válido',
  'pages.payment-links.new-payment-link.form.validations.name':
    'É necessário informar um nome',
  'pages.payment-links.new-payment-link.form.validations.daysToExpire':
    'É necessário informar um valor válido',
  'pages.payment-links.new-payment-link.form.validations.daysToExpire.min':
    'É necessário informar um valor válido',
  'pages.payment-links.new-payment-link.form.validations.expiresIn':
    'É necessário informar um valor válido para o vencimento',
  'pages.payment-links.new-payment-link.form.validations.expiresIn.min':
    'É necessário informar um valor válido para o vencimento',
  'pages.payment-links.new-payment-link.form.validations.methods':
    'É necessário selecionar pelo menos um método de pagamento',
  'pages.payment-links.new-payment-link.form.validations.methods.min':
    'É necessário selecionar pelo menos um método de pagamento',
  'pages.payment-links.new-payment-link.form.validations.installment.max':
    'O valor máximo para o parcelamento é ',
  'pages.payment-links.new-payment-link.form.validations.installment.min':
    'O valor mínimo para o parcelamento é 1',
  'pages.payment-links.new-payment-link.form.validations.installment.':
    'É necessário informar um parcelamento válido',

  'pages.payment-link-details.details': 'Detalhes',
  'pages.payment-link-details.details.name': 'Nome',
  'pages.payment-link-details.details.status': 'Status',
  'pages.payment-link-details.details.copy-link': 'Copiar link',
  'pages.payment-link-details.copied-link': 'Link copiado!',
  'pages.payment-link-details.details.amount-and-shipping-fee': 'Valor + Frete',
  'pages.payment-link-details.details.discounts': 'Desconto',
  'pages.payment-link-details.details.created-in': 'Criado em',
  'pages.payment-link-details.details.expiration-date': 'Válido até',
  'pages.payment-link-details.details.description': 'Descrição do produto',
  'pages.payment-link-details.payment-methods': 'Meios de pagamento',
  'pages.payment-link-details.payment-methods.expires-in':
    'Dias para o vencimento',
  'pages.payment-link-details.payment-methods.installment': 'Parcelamento',
  'pages.payment-link-details.transactions-history': 'Histórico de Transações',
  'pages.payment-link-details.no-transactions':
    'Ainda não foram realizadas transações com esse link',
  'pages.payment-link-details.update-payment-link.form.name': 'Nome do link',
  'pages.payment-link-details.update-payment-link.form.status': 'Status',
  'pages.payment-link-details.update-payment-link.form.name.placeholder':
    'Ex. Camisa polo',
  'pages.payment-link-details.update-payment-link.form.amount':
    'Valor da cobrança',
  'pages.payment-link-details.update-payment-link.form.amount.placeholder':
    'R$ 0.00',
  'pages.payment-link-details.update-payment-link.form.discounts':
    'Valor do desconto (%)',
  'pages.payment-link-details.update-payment-link.form.shipping-fee':
    'Valor do frete (Opcional)',
  'pages.payment-link-details.update-payment-link.form.shipping-fee.placeholder':
    'R$ 0.00',
  'pages.payment-link-details.update-payment-link.form.seller-name':
    'Nome vendedor/loja (Opcional)',
  'pages.payment-link-details.update-payment-link.form.pixel-facebook':
    'PIXEL FACEBOOK (Opcional)',
  'pages.payment-link-details.update-payment-link.form.dueDate':
    'Data de vencimento',
  'pages.payment-link-details.update-payment-link.form.dueDate.placeholder':
    'Qual a data de vencimento?',
  'pages.payment-link-details.payment-link.methods': 'Métodos de pagamento',

  'pages.payment-link-details.update-payment-link.boleto': 'Boleto',
  'pages.payment-link-details.update-payment-link.credit': 'Cartão de crédito',
  'pages.payment-link-details.update-payment-link.pix': 'PIX',
  'pages.payment-link-details.update-payment-link.form.expires-in':
    'Dias para pagamento do boleto',
  'pages.payment-link-details.update-payment-link.form.expires-in.placeholder':
    'Dias para vencimento após emitir o boleto',
  'pages.payment-link-details.update-payment-link.form.installment':
    'Parcelamento',
  'pages.payment-link-details.update-payment-link.form.validations.amount':
    'É necessário informar um valor válido',
  'pages.payment-link-details.update-payment-link.form.validations.amount.min':
    'O valor mínimo é R$ 3,00',
  'pages.payment-link-details.update-payment-link.form.validations.discounts':
    'É necessário informar um valor válido',
  'pages.payment-link-details.update-payment-link.form.validations.shippingFee':
    'É necessário informar um valor válido para o frete',
  'pages.payment-link-details.update-payment-link.form.validations.shippingFee.min':
    'É necessário informar um valor válido',
  'pages.payment-link-details.update-payment-link.form.validations.name':
    'É necessário informar um nome',
  'pages.payment-link-details.update-payment-link.form.validations.dueDate':
    'É necessário informar uma data válido',
  'pages.payment-link-details.update-payment-link.form.validations.dueDate.min':
    'É necessário informar uma data',
  'pages.payment-link-details.update-payment-link.form.validations.expiresIn':
    'É necessário informar um valor válido para o vencimento',
  'pages.payment-link-details.update-payment-link.form.validations.expiresIn.min':
    'É necessário informar um valor válido para o vencimento',
  'pages.payment-link-details.update-payment-link.form.validations.methods':
    'É necessário selecionar pelo menos um método de pagamento',
  'pages.payment-link-details.update-payment-link.form.validations.methods.min':
    'É necessário selecionar pelo menos um método de pagamento',
  'pages.payment-link-details.update-payment-link.form.validations.installment.max':
    'O valor máximo para o parcelamento é ',
  'pages.payment-link-details.update-payment-link.form.validations.installment.min':
    'O valor mínimo para o parcelamento é 1',
  'pages.payment-link-details.update-payment-link.form.validations.installment.':
    'É necessário informar um parcelamento válido',

  'pages.conciliation.form-establishment': 'Selecione um estabelecimento',
  'pages.conciliation.button-register-fees': 'Cadastrar taxas',
  'pages.conciliation.button-send-summary': 'Enviar resumo de caixa',
  'pages.conciliation.button-report': 'Relatórios',
  'pages.conciliation.button-sales': 'Vendas',
  'pages.conciliation.button-receivements': 'Recebimentos',
  'pages.conciliation.total-sales': 'Total de vendas',
  'pages.conciliation.balance-receivable': 'Saldo a receber',
  'pages.conciliation.chargeback/cancellations': 'ChargeBack e Cancelamentos',
  'pages.conciliation.advance-sales': 'Vendas com antecipação',
  'pages.conciliation.movement-per-day': 'Movimento por dia',
  'pages.conciliation.sale-indicators': 'Indicadores de vendas',
  'pages.conciliation.sale-indicators.transactions-by-brand':
    'Transações por bandeira',
  'pages.conciliation.sale-indicators.transactions-by-administrator':
    'Transações por adminstradora',
  'pages.conciliation.sales-by-payment-method': 'Vendas por Forma de Pagamento',
  'pages.conciliation.date-range': 'Período',
  'pages.conciliation.top-nav-establishments': 'Estabelecimentos',
  'pages.conciliation.top-nav-administrators': 'Administradoras ',
  'pages.conciliation.table-amount': 'Quantidade',
  'pages.conciliation.table-administrator': 'Operadora',
  'pages.conciliation.table-establishment': 'Estabelecimento',
  'pages.conciliation.table-value': 'Valor',

  'pages.sales.sales-amount': 'Quantidade de vendas',
  'pages.sales.gross-amount': 'Valor bruto',
  'pages.sales.verified-sales': 'Vendas conferidas',
  'pages.sales.select-establishments': 'Selecionar o Estabelecimento',
  'pages.sales.select-bank-account': 'Selecionar a Conta bancária',
  'pages.sales.select-transaction-type': 'Tipo de transação',
  'pages.sales.date-range': 'Período',
  'pages.sales.table-status': 'Status',
  'pages.sales.table-date': 'Data',
  'pages.sales.table-establishment': 'Estabelecimento',
  'pages.sales.table-provider': 'Operadora',
  'pages.sales.table-brand': 'Bandeira',
  'pages.sales.table-type': 'Tipo',
  'pages.sales.table-amount': 'Valor',
  'pages.sales.table-fee': 'Taxa',
  'pages.sales.table-installments': 'Parcelas',
  'pages.sales.table-nsu': 'NSU',
  'pages.sales.table-actions': 'Ações',
  'pages.sales.table-actions.details': 'Detalhes',
  'pages.sales.no-sales': 'Não há vendas para o período/filtros selecionados.',

  'pages.receivements.table-status': 'Status',
  'pages.receivements.table-date': 'Data',
  'pages.receivements.table-provider': 'Operadora',
  'pages.receivements.table-account': 'Conta',
  'pages.receivements.table-establishment': 'Estabelecimento',
  'pages.receivements.table-amount': 'Valor',
  'pages.receivements.table-actions': 'Ações',
  'pages.receivements.no-receivements':
    'Não há recebimentos para o período/filtros selecionados.',

  'pages.payment-by-link.payer-label': 'Você está pagando',
  'pages.payment-by-link.payment-description': 'Descrição',
  'pages.payment-by-link.amount': 'Valor',
  'pages.payment-by-link.shipping-fee': 'Frete',
  'pages.payment-by-link.total-amount': 'Total',
  'pages.payment-by-link.discounts': 'Desconto (Boleto/PIX)',
  'pages.payment-by-link.personal-data': 'Dados pessoais',
  'pages.payment-by-link.payment-data': 'Dados de pagamento',
  'pages.payment-by-link.address': 'Endereço',
  'pages.payment-by-link.safe-environment':
    'Ambiente criptografado e 100% seguro',
  'pages.payment-by-link.not-found':
    'Link de pagamento procurado está inválido ou não existe.',
  'pages.payment-by-link.error': 'Nao foi possível prosseguir com o processamento da transação.',

  'pages.transaction-succeeded.congratulations':
    'Transação gerada com sucesso!',
  'pages.payment-by-link.form.validation.security-code':
    'Informe um valor para o CVC válido',
  'pages.payment-by-link.form.validation.month': 'Informe um mês válido',
  'pages.payment-by-link.form.validation.year': 'Informe um ano válido',
  'pages.payment-by-link.form.validation.holder-name':
    'É necessário informar o nome do titular',
  'pages.payment-by-link.form.validation.card-number':
    'É necessário informar um número de cartão válido',
  'pages.payment-by-link.form.validation.installments':
    'É necessário selecionar um parcelamento',
  'pages.transaction-succeeded.credit.message':
    'Pagamento realizado com sucesso!',
  'pages.transaction-succeeded.boleto.message': 'Boleto gerado com sucesso!',
  'pages.transaction-succeeded.boleto.description':
    'Para realizar o pagamento basta copiar o código de barras apresentado abaixo ou pagar o boleto disponibilizado!',
  'pages.transaction-succeeded.boleto.button': 'Visualizar e imprimir boleto',
  'pages.transaction-succeeded.PIX.message': 'PIX gerado com sucesso!',
  'pages.transaction-succeeded.PIX.description':
    'Para realizar o pagamento bastar ler o QR code apresentado abaixo ou copiar o código!',
  'pages.transaction-succeeded.PIX.button': 'Copiar código',

  'pages.account.personal-data': 'Dados pessoais',
  'pages.account.address': 'Endereço',
  'pages.account.change-password': 'Alterar senha',
  'pages.account.change-avatar': 'Alterar foto de perfil',

  'pages.account.bank-account': 'Dados bancários',
  'pages.account.bank-account.code': 'Banco',
  'pages.account.bank-account.branch': 'Agência',
  'pages.account.bank-account.account': 'Conta',
  'pages.account.bank-account.type': 'Tipo de conta',
  'pages.account.bank-account.type.placeholder': 'Selecione um banco',
  'pages.account.bank-account.type.savings': 'Poupança',
  'pages.account.bank-account.type.checking': 'Conta corrente',

  'pages.account.bank-account.form.validation.code':
    'É necessário informar um banco',
  'pages.account.bank-account.form.validation.branch':
    'É necessário informar o número da agência',
  'pages.account.bank-account.form.validation.account':
    'É necessário informar o número da conta',
  'pages.account.bank-account.form.validation.type':
    'É necessário informar o tipo de conta',

  'pages.account.credential': 'Credenciais',
  'pages.account.credential.security-message':
    'Não compartilhar essas credenciais',
  'pages.account.credential.username': 'Usuário',
  'pages.account.credential.password': 'Senha',

  'pages.submit-document.necessary-documents': 'Documentos necessários',
  'pages.submit-document.necessary-documents.PF': 'Pessoa física',
  'pages.submit-document.necessary-documents.PJ': 'Pessoa Jurídica',
  'pages.submit-document.necessary-documents.identification':
    '* Documento de identidade com foto (RG e CPF ou CNH) do proprietário/sócios.',
  'pages.submit-document.necessary-documents.residence':
    '* Comprovante de residência em seu nome (ou de familiares) do proprietário/sócios.',
  'pages.submit-document.necessary-documents.activity':
    '* Comprovante da atividade exercida (cartão de visita, link de site, nota fiscal de compra de produtos ou prestação de serviços, etc).',
  'pages.submit-document.necessary-documents.identification.PJ':
    '* Cópia do cartão do CNPJ ou cópia do contrato social da empresa.',
  'pages.submit-document.necessary-documents.identification.short':
    'Documento de identificação (Proprietário/sócios)',
  'pages.submit-document.necessary-documents.residence.short':
    'Comprovante de residência (Proprietário/sócios)',
  'pages.submit-document.necessary-documents.activity.short':
    'Comprovante de atividade exercida',
  'pages.submit-document.necessary-documents.cnpj.short':
    'Cópia do cartão CNPJ ou cópia do contrato social da empresa.',
  'pages.submit-document.warning-message':
    'ATENÇÃO: Os documentos devem ser do titular indicado no cadastro e devem estar legíveis.',
  'pages.submit-document.description':
    'Enviar somente os documentos necessários ao tipo de conta. (Enviar um por vez.)',

  'pages.recurring.table-status': 'Status',
  'pages.recurring.table-date': 'Assinatura',
  'pages.recurring.table-payment-method': 'Método de Pagamento',
  'pages.recurring.table-installments': 'Parcelas Pagas',
  'pages.recurring.table-last-payment': 'Último Pagamento',
  'pages.recurring.table-amount': 'Valor',
  'pages.recurring.table-frequency': 'Frenquência',
  'pages.recurring.table-actions': 'Ações',
  'pages.recurring.table-user': 'Usuário',
  'pages.recurring.table-details': 'Mais detalhes',

  'pages.recurring-plans.table-status': 'Status',
  'pages.recurring-plans.table-date': 'Criado',
  'pages.recurring-plans.table-title': 'Plano',
  'pages.recurring-plans.table-amount': 'Valor',
  'pages.recurring-plans.create-plan': 'Novo Plano',
  'pages.recurring-plans.create-title': 'Criar Novo Plano',
  'pages.recurring-plans.form.name': 'Nome do Plano',
  'pages.recurring-plans.form.name.placeholder':
    'Escreva o nome do plano de assinatura',
  'pages.recurring-plans.form.amount': 'Valor da Assinatura',
  'pages.recurring-plans.form.amount.placeholder': 'R$ 57,00',
  'pages.recurring-plans.form.frequency': 'Frequência da Assinatura(em dias)',
  'pages.recurring-plans.form.frequency.placeholder':
    'Ex: 30 para cobrança mensal',

  'pages.subscriptions.filter.plan-name': 'Nome do plano',
  'pages.subscriptions.filter.customer-name': 'Nome do cliente',
  'pages.subscriptions.filter.customer-doc': 'Documento do cliente',
  'pages.subscriptions.filter.status': 'Status',
  'pages.subscriptions.filter.status.all': 'Todos',
  'pages.subscriptions.no-subscriptions':
    'Ainda não existem assinaturas e/ou não foram encontradas.',

  'pages.create-subscription.title':
    'Olá, você está realizando a assinatura do plano ',
  'pages.create-subscription.billing-day': 'Você será cobrado todo dia ',
  'pages.create-subscription.frequency.weekly': 'por semana',
  'pages.create-subscription.frequency.monthly': 'por mês',
  'pages.create-subscription.frequency.annually': 'por ano',
  'pages.create-subscription.subscribe': 'Assinar',

  'pages.recurrence-plans.filter.name': 'Nome',
  'pages.recurrence-plans.filter.interval': 'Período',
  'pages.recurrence-plans.filter.interval.all': 'Todos',
  'pages.recurrence-plans.filter.status': 'Status',
  'pages.recurrence-plans.filter.status.all': 'Todos',
  'pages.recurrence-plans.plans': 'Planos',
  'pages.recurrence-plans.create': 'Novo Plano',
  'pages.recurrence-plans.table-status': 'Status',
  'pages.recurrence-plans.table-name': 'Nome',
  'pages.recurrence-plans.table-billing-type': 'Tipo de cobrança',
  'pages.recurrence-plans.table-payment-type': 'Método de pagamento',
  'pages.recurrence-plans.table-interval': 'Tipo de intervalo',
  'pages.recurrence-plans.table-interval-count': 'Intervalo',
  'pages.recurrence-plans.table-cycles': 'Ciclos',
  'pages.recurrence-plans.table-trial-period-days': 'Dias grátis',
  'pages.recurrence-plans.table-amount': 'Valor',
  'pages.recurrence-plans.table-created-in': 'Criado em',
  'pages.recurrence-plans.table-actions': 'Ações',
  'pages.recurrence-plans.not-found':
    'Ainda não existem planos e/ou não foram encontrados.',

  'pages.recurrence.create-plan-modal.create': 'Criar plano',
  'pages.recurrence.create-plan-modal.step-data': 'Dados básicos',
  'pages.recurrence.create-plan-modal.step-receivements':
    'Informações de recebimento',

  'pages.recurrence.create-plan-modal.form.name': 'Nome do Plano',
  'pages.recurrence.create-plan-modal.form.description': 'Descrição',
  'pages.recurrence.create-plan-modal.form.description.placeholder':
    'Uma breve descrição do plano',
  'pages.recurrence.create-plan-modal.form.amount': 'Valor',
  'pages.recurrence.create-plan-modal.form.trial-period-days':
    'Período gratuito (em dias)',
  'pages.recurrence.create-plan-modal.form.trial-period-days.placeholder':
    'Dias grátis',
  'pages.recurrence.create-plan-modal.form.cycles-to-suspend':
    'Ciclos para suspender',
  'pages.recurrence.create-plan-modal.form.cycles-to-suspend.placeholder':
    'Número de cobranças em atraso para suspender',
  'pages.recurrence.create-plan-modal.form.days-to-cancel':
    'Dias para cancelar',
  'pages.recurrence.create-plan-modal.form.days-to-cancel.placeholder':
    'Dias após suspensão para cancelar assinatura',
  'pages.recurrence.create-plan-modal.form.billing-type': 'Tipo de cobrança',
  'pages.recurrence.create-plan-modal.form.interval': 'Tipo de intervalo',
  'pages.recurrence.create-plan-modal.form.interval-count':
    'Intervalo de cobrança (Espaçamento entre os intervalos)',
  'pages.recurrence.create-plan-modal.form.interval-count.placeholder':
    'Espaçamento entre intervalos',
  'pages.recurrence.create-plan-modal.form.payment-type': 'Formas de pagamento',
  'pages.recurrence.create-plan-modal.form.split': 'Divisão de pagamento',
  'pages.recurrence.create-plan-modal.form.split-type': 'Tipo de divisão',
  'pages.recurrence.create-plan-modal.form.recipient': 'Destinatário',
  'pages.recurrence.create-plan-modal.form.recipient.placeholder':
    'Informe o documento do destinatário',

  'pages.recurrence.create-plan-modal.form.validations.name':
    'É necessário informar um nome',
  'pages.recurrence.create-plan-modal.form.validations.description':
    'É necessário informar uma descrição',
  'pages.recurrence.create-plan-modal.form.validations.billing-type':
    'É necessário informar um tipo de cobrança',
  'pages.recurrence.create-plan-modal.form.validations.amount':
    'É necessário informar um valor',
  'pages.recurrence.create-plan-modal.form.validations.amount.min':
    'O valor deve ser no mínimo R$ 3.00',
  'pages.recurrence.create-plan-modal.form.validations.trial-period-days.min':
    'O período gratuito deve ser maior ou igual a 0',
  'pages.recurrence.create-plan-modal.form.validations.cycles-to-suspend':
    'É necessário informar quantos ciclos para a suspensão',
  'pages.recurrence.create-plan-modal.form.validations.cycles-to-suspend.min':
    'O mínimo de ciclos é 0',
  'pages.recurrence.create-plan-modal.form.validations.days-to-cancel':
    'É necessário informar quantos dias para cancelar',
  'pages.recurrence.create-plan-modal.form.validations.days-to-cancel.min':
    'O mínimo de dias para cancelar é 5',
  'pages.recurrence.create-plan-modal.form.validations.interval':
    'É necessário informar um tipo de intervalo',
  'pages.recurrence.create-plan-modal.form.validations.interval-count':
    'É necessário informar um intervalo',
  'pages.recurrence.create-plan-modal.form.validations.interval-count.min':
    'O intervalo deve ser maior ou igual a 1',
  'pages.recurrence.create-plan-modal.form.validations.payment-type':
    'É necessário informar uma forma de pagamento',
  'pages.recurrence.create-plan-modal.form.validations.recipient':
    'É necessário informar um destinatário',
  'pages.recurrence.create-plan-modal.form.validations.recipient-already-exists':
    'Destinatário já adicionado a uma regra de divisão.',
  'pages.recurrence.create-plan-modal.form.validations.type':
    'É necessário informar um tipo de divisão',

  'pages.recurrence-plan-details.details': 'Detalhes do plano',
  'pages.recurrence-plan-details.payment': 'Pagamento',
  'pages.recurrence-plan-details.split-rules': 'Regras de divisão',
  'pages.recurrence-plan-details.subscriptions': 'Assinaturas',
  'pages.recurrence-plan-details.charges': 'Cobranças emitidas',
  'pages.recurrence-plan-details.link': 'Link',
  'pages.recurrence-plan-details.copied-link': 'Link copiado!',
  'pages.recurrence-plan-details.not-found':
    'O plano que você está buscando não existe e/ou está indisponível no momento.',
  'pages.recurrence-plan-details.no-subscriptions':
    'Não há assinaturas para este plano.',

  'pages.subscription-details.details': 'Detalhes da Assinatura',
  'pages.subscription-details.select-status': 'Selecione um status',
  'pages.subscription-details.cycle': 'Cobranças emitidas',
  'pages.subscription-details.open-charges': 'Cobranças em aberto',
  'pages.subscription-details.amount': 'Valor',
  'pages.subscription-details.last-billing-date': 'Data da última cobrança',
  'pages.subscription-details.next-billing-date': 'Data da próxima cobrança',
  'pages.subscription-details.customer-details': 'Detalhes do cliente',
  'pages.subscription-details.history': 'Histórico',
  'pages.subscription-details.history.table-date': 'Data',
  'pages.subscription-details.history.table-cycle': 'Ciclo',
  'pages.subscription-details.history.table-operation': 'Operação',
  'pages.subscription-details.history.table-operation.created': 'Criada',
  'pages.subscription-details.history.table-operation.charged': 'Cobrança',
  'pages.subscription-details.history.table-operation.activated': 'Ativada',
  'pages.subscription-details.history.table-operation.suspended': 'Suspensa',
  'pages.subscription-details.history.table-operation.canceled': 'Cancelada',
  'pages.subscription-details.charges': 'Cobranças',
  'pages.subscription-details.charges.table-status': 'Status',
  'pages.subscription-details.charges.status.paid': 'Pago',
  'pages.subscription-details.charges.status.copy-link': 'Link copiado!',
  'pages.subscription-details.charges.status.pending': 'Em aberto',
  'pages.subscription-details.charges.table-billing-in': 'Emitida em',
  'pages.subscription-details.charges.table-pay-in': 'Pago em',
  'pages.subscription-details.charges.table-due-date': 'Vencimento',
  'pages.subscription-details.charges.table-document': 'Documento',
  'pages.subscription-details.not-found':
    'A assinatura que você está buscando não existe e/ou está indisponível no momento.',

  'pages.subscription-succeeded.message': 'Assinatura realizada com sucesso!',

  'pages.search-payment-bonds.welcome': 'Bem vindo ao portal de pagamentos da ',
  'pages.search-payment-bonds.title':
    'Para buscar todos os titulos em aberto em seu nome digite os dados abaixo:',
  'pages.search-payment-bonds.search-button': 'Entrar',
  'pages.search-payment-bonds.input-placeholder': 'Digite seu CNPJ ou CPF',

  'pages.payment-bonds-result.total-selected-bonds':
    'Total de títulos selecionados:',
  'pages.payment-bonds-result.pay-button': 'Pagar',
  'pages.payment-bonds-result.title': 'Meus títulos',
  'pages.payment-bonds-result.total': 'Total de títulos:',
  'pages.payment-bonds-result.export-excel': 'Exportar para excel',
  'pages.payment-bonds-result.table-select': 'Selecionar',
  'pages.payment-bonds-result.table-establishment': 'Nome do estabelecimento',
  'pages.payment-bonds-result.table-cnpj': 'CNPJ',
  'pages.payment-bonds-result.table-NF': 'Número da NF',
  'pages.payment-bonds-result.table-due-date': 'Vencimento',
  'pages.payment-bonds-result.table-installment': 'Parcela',
  'pages.payment-bonds-result.table-documentNumber': 'Número do documento',

  'pages.payment-bonds-orders.title': 'Meus borderôs',
  'pages.payment-bonds-orders.table-reference': 'Referência',
  'pages.payment-bonds-orders.table-method': 'Método',
  'pages.payment-bonds-orders.table-status': 'Status',
  'pages.payment-bonds-orders.table-due-date': 'Vencimento',
  'pages.payment-bonds-orders.table-amount': 'Valor',
  'pages.payment-bonds-orders.table-created-in': 'Emitido em',
  'pages.payment-bonds-orders.table-documents': 'Documentos',
  'pages.payment-bonds-orders.table-actions': 'Ações',
  'pages.payment-bonds-orders.table-actions.details': 'Detalhes',
  'pages.payment-bonds-orders.table-actions.cancel': 'Cancelar',
  'pages.payment-bonds-orders.details': 'Detalhes',
  'pages.payment-bonds-orders.details.table-reference': 'Referência',
  'pages.payment-bonds-orders.details.table-document-number':
    'Número do documento',
  'pages.payment-bonds-orders.details.table-installment': 'Parcela',
  'pages.payment-bonds-orders.details.table-due-date': 'Vencimento',
  'pages.payment-bonds-orders.details.table-amount': 'Valor',
  'pages.payment-bonds-orders.not-found': 'Você ainda não possui borderôs',
  'pages.payment-bonds-orders.not-found-for-filters':
    'Nenhum borderô encontrado!',

  'pages.payment-bonds-checkout.bonds-info': 'Informações dos títulos',
  'pages.payment-bonds-checkout.table-establishment': 'Estabelecimento',
  'pages.payment-bonds-checkout.table-value': 'Valor',
  'pages.payment-bonds-checkout.table-details': 'Número do documento',
  'pages.payment-bonds-checkout.total-amount': 'Valor total',
  'pages.payment-bonds-checkout.payment-details': 'Dados do pagamento',
  'pages.payment-bonds-checkout.form.card-number': 'Número do cartão',
  'pages.payment-bonds-checkout.form.owner-name': 'Nome do titular',
  'pages.payment-bonds-checkout.form.year': 'Ano',
  'pages.payment-bonds-checkout.form.month': 'Mês',
  'pages.payment-bonds-checkout.form.security-code': 'Código de segurança',
  'pages.payment-bonds-checkout.form.installment': 'Parcelamento',
  'pages.payment-bonds-checkout.pay-button': 'Pagar',

  'pages.payment-bonds.form-errors.required-field':
    'Este campo não pode estar vazio',
  'pages.payment-bonds.form-errors.negative-field':
    'Este campo não pode ser negativo ou zero',
  'pages.payment-bonds.form-errors.non-integer-field':
    'Este campo deve ser inteiro',
  'pages.payment-bonds.form-errors.max-digits-field':
    'Número máximo de dígitos: ',
  'pages.payment-bonds.form-errors.min-digits-field':
    'Número mínimo de dígitos: ',
  'pages.payment-bonds.form-errors.max-value-field': 'Valor máximo: ',
  'pages.payment-bonds.form-errors.min-value-field': 'Valor mínimo: ',

  // payment page
  'pages.payment.doc-form.title':
    'Por favor, insira o CPF ou CNPJ para prosseguir',
  'pages.payment.doc-form.submit-form': 'Avançar',
  'pages.payment.doc-form.sending-to': 'Enviando para:',
  'pages.payment.doc-form.full-name': 'Nome Completo',
  // -user data form
  'pages.payment.user-data-form.title': 'Dados do usuário',
  'pages.payment.user-data-form.email': 'Email',
  'pages.payment.user-data-form.zip-code': 'CEP',
  'pages.payment.user-data-form.state': 'Estado',
  'pages.payment.user-data-form.city': 'Cidade',
  'pages.payment.user-data-form.district': 'Bairro',
  'pages.payment.user-data-form.place': 'Logradouro',
  'pages.payment.user-data-form.complement': 'Complemento',
  'pages.payment.user-data-form.number': 'Número',
  'pages.payment.user-data-form.first-name': 'Primeiro Nome',
  'pages.payment.user-data-form.last-name': 'Último Nome',
  'pages.payment.user-data-form.phone': 'Telefone',
  'pages.payment.user-data-form.birth-date': 'Data de Nascimento',

  'pages.payment.user-data-form.validations.valid-email':
    'O email deve ser válido',

  'pages.payment.wizard.progress-bar.step': 'Passo',
  'pages.payment.wizard.next': 'Avançar',
  'pages.payment.wizard.previous': 'Voltar',
  'pages.payment.payment-form.title': 'Dados do pagamento',
  // -general form validations
  'pages.payment.form.1.wrong-type': 'Valor inválido',
  'pages.payment.form.validations.negative': 'O campo deve ser positivo',
  'pages.payment.form.validations.min-value': 'Valor mínimo: ',
  'pages.payment.form.validations.max-value': 'Valor máximo: ',
  'pages.payment.form.validations.float-number': 'O campo deve ser inteiro',
  'pages.payment.form.validations.required-field':
    'O campo não pode estar vazio',
  'pages.payment.form.validations.max-field-digits':
    'Tamanho máximo de dígitos:',
  'pages.payment.form.validations.min-field-digits':
    'Tamanho mínimo de dígitos:',
  'pages.payment.payment-form.amount': 'Valor',
  'pages.drop-checkout.error': 'Ops, parece que ocorreu um erro!',
  'pages.drop-checkout.not-found':
    'Checkout procurado está indisponível ou não existe.',

  'pages.checkouts.status': 'Status',
  'pages.checkouts.status.all': 'Todos',
  'pages.checkouts.status.active': 'Ativo',
  'pages.checkouts.status.inactive': 'Inativo',
  'pages.checkouts.card.title': 'Histórico de checkouts',
  'pages.checkouts.button.search': 'Buscar',
  'pages.checkouts.button.create-checkout': 'Criar Checkout',
  'pages.checkouts.button.personalize': 'Personalizar',
  'pages.checkouts.input.search-name': 'Nome do checkout',
  'pages.checkouts.input.search-name.placeholder': 'Digite o nome do checkout',
  'pages.checkouts.table-column.status': 'Status',
  'pages.checkouts.table-column.methods': 'Métodos aceitos',
  'pages.checkouts.table-column.name': 'Nome',
  'pages.checkouts.table-column.product': 'Produto',
  'pages.checkouts.table-column.link': 'Link',
  'pages.checkouts.table-column.amount': 'Valor',
  'pages.checkouts.table-column.created-in': 'Criado em',
  'pages.checkouts.table-column.details': 'Detalhes',

  'pages.new-checkout.basic-config': 'Configurações básicas',
  'pages.new-checkout.pixel-config': 'Configurações de Pixel',
  'pages.new-checkout.pixel': 'Pixel',
  'pages.new-checkout.buy-notification': 'Notificações de compra',
  'pages.new-checkout.product-price': 'Valor do prduto',
  'pages.new-checkout.payments': 'Pagamentos',
  'pages.new-checkout.social-proof': 'Prova social',
  'pages.new-checkout.timer': 'Contador',
  'pages.new-checkout.payments.warning':
    'É necessário selecionar pelomenos um método de pagamento',
  'pages.new-checkout.discount': 'Desconto para formas de pagamento',
  'pages.new-checkout.form.checkout-name': 'Nome do checkout',
  'pages.new-checkout.form.checkout-product': 'Nome do produto',
  'pages.new-checkout.form.checkout-product-description':
    'Descrição do produto',
  'pages.new-checkout.form.checkout-product-image': 'Imagem do produto',
  'pages.new-checkout.form.checkout-product-image-preview': 'Prévia',
  'pages.new-checkout.form.credit-card': 'Cartão de crédito',
  'pages.new-checkout.form.credit-card.installments': 'Parcelas',
  'pages.new-checkout.form.boleto': 'Boleto',
  'pages.new-checkout.form.boleto.due-date': 'Dias para vencimento do boleto',
  'pages.new-checkout.form.pix': 'PIX',
  'pages.new-checkout.form.discount-boleto':
    'Valor de desconto para boleto (%)',
  'pages.new-checkout.form.discount-pix': 'Valor de desconto para pix (%)',
  'pages.new-checkout.form.no-discount': 'Deixe em branco para 0% de desconto.',
  'pages.new-checkout.form.timer.background-color': 'Cor de fundo',
  'pages.new-checkout.form.timer.text-color': 'Cor de texto',
  'pages.new-checkout.form.timer.set-time': 'Defina o tempo (em minutos)',
  'pages.new-checkout.form.timer.title': 'Título do contador',
  'pages.new-checkout.form.timer.title-placeholder': 'Tempo limitado',
  'pages.new-checkout.form.timer.text': 'Texto do contador',
  'pages.new-checkout.form.timer.text-placeholder':
    'Preço promocional encerra em:',
  'pages.new-checkout.form.create': 'Criar',
  'pages.new-checkout.form.social-proof.select-image': 'Selecione uma foto',
  'pages.new-checkout.form.person-image-preview': 'Prévia',
  'pages.new-checkout.form.evaluator': 'Avaliador',
  'pages.new-checkout.form.rating': 'Nota da avaliação (1-5)',
  'pages.new-checkout.form.description': 'Descrição',

  'pages.new-checkout.buy-notification.perHour':
    'XX Pessoas compraram esse produto na última hora!',
  'pages.new-checkout.buy-notification.perUnit':
    'FULANO acabou de comprar uma unidade!',
  'pages.new-checkout.buy-notification.buying-now':
    'Pessoas estão comprando agora!',
  'pages.new-checkout.social-proof.button': 'Adicionar',

  'pages.new-checkout.social-proof.table.image': 'Imagem',
  'pages.new-checkout.social-proof.table.name': 'Nome',
  'pages.new-checkout.social-proof.table.comment': 'Comentário',
  'pages.new-checkout.social-proof.table.evaluation': 'Avaliação',
  'pages.new-checkout.social-proof.table.remove': 'Remover',

  'pages.drop-checkout.product-info': 'Dados do produto',
  'pages.drop-checkout.product-description': 'Descrição do produto:',
  'pages.drop-checkout.product-value': 'Valor:',
  'pages.drop-checkout.product-shipping-fee': 'Frete:',
  'pages.drop-checkout.product-free-shipping-fee': 'Grátis',
  'pages.drop-checkout.product-total': 'Total:',
  'pages.drop-checkout.product-amount': 'Quantidade: 1 unidade',

  'pages.drop-checkout.user-info': 'Dados pessoais',
  'pages.drop-checkout.discount-of': 'Desconto de ',
  'pages.drop-checkout.for-payments-by': ' para pagamentos via',

  'pages.drop-checkout.immediate-aprove': 'APROVAÇÃO IMEDIATA',
  'pages.drop-checkout.pix.short': 'pix',
  'pages.drop-checkout.boleto.short': 'boleto',
  'pages.drop-checkout.credit.short': 'Crédito',
  'pages.drop-checkout.boleto': 'Boleto',

  'pages.drop-checkout.aprove-time': 'ATÉ 3 DIAS ÚTEIS PARA APROVAÇÃO',
  'pages.drop-checkout.buy-now': 'COMPRAR AGORA',
  'pages.drop-checkout.recomendations': 'Quem comprou recomenda',

  'pages.webhooks.new': 'Novo Webhook',
  'pages.webhooks.delete': 'Apagar Webhook',
  'pages.webhooks.delete.message':
    'Após apagar, a ação não poderá ser desfeita. Deseja mesmo apagar?',
  'pages.webhooks.table-type': 'Tipo',
  'pages.webhooks.table-date': 'Data',
  'pages.webhooks.table-url': 'Endpoint',
  'pages.webhooks.form.url': 'Endpoint',
  'pages.webhooks.form.type': 'Tipo',
  'pages.webhooks.form.type.placeholder': 'Selecione o tipo',

  'pages.webhooks.form.validation.url': 'É necessário informar o endpoint',
  'pages.webhooks.form.validation.type': 'É necessário informar o tipo',

  'pages.change-subscription-card.title':
    'Alterar cartão de crédito da assinatura',

  'pages.splits.title': 'Divisões de pagamentos',
  'pages.splits.no-splits': 'Não há divisões no período selecionado.',
  'pages.splits.no-receivables':
    'Ainda não há recebíveis para a divisão selecionada.',
  'pages.splits.receivables': 'Recebíveis',

  'pages.restrited-feature.title':
    'Ops... Infelizmente você ainda não possui acesso a essa funcionalidade.',
  'pages.restrited-feature.description':
    'Parece que a funcionalidade que você está tentando acessar pertence a um plano diferente do seu plano atual. Para ter acesso a essa funcionalidade entre em contato com o suporte e solicite a migração do seu plano. (contato@switchpay.com.br)',

  'pages.login-profiles.new': 'Criar perfil de login',
  'pages.login-profiles.table.name': 'Nome',
  'pages.login-profiles.table.email': 'Email',
  'pages.login-profiles.table.is-primary': 'Primário',
  'pages.login-profiles.no-profiles': 'Não há perfis de login cadastrados.',
  'pages.login-profiles.delete': 'Apagar perfil',
};

import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@dropInCheckout/CREATE_DROP_IN_CHECKOUT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@dropInCheckout/CREATE_DROP_IN_CHECKOUT_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@dropInCheckout/UPDATE_DROP_IN_CHECKOUT_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@dropInCheckout/UPDATE_DROP_IN_CHECKOUT_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@dropInCheckout/FAILURE': {
        draft.loading = false;

        break;
      }

      default:
    }
  });

export default reducer;

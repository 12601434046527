export function signInRequest({ doc, password, userId }) {
  return {
    type: '@customer/SIGN_IN_REQUEST',
    payload: { doc, password, userId },
  };
}

export function signInSuccess({ token, doc }) {
  return {
    type: '@customer/SIGN_IN_SUCCESS',
    payload: { token, doc },
  };
}

export function failure() {
  return {
    type: '@customer/FAILURE',
    payload: {},
  };
}

// admin

export function adminGetUserRequest({ setUser, setBalance, username }) {
  return {
    type: '@user/ADMIN_GET_USER_REQUEST',
    payload: { setUser, setBalance, username },
  };
}

export function adminGetUserSuccess() {
  return {
    type: '@user/ADMIN_GET_USER_SUCCESS',
    payload: {},
  };
}

export function adminCreateUserRequest({ user }) {
  return {
    type: '@user/ADMIN_CREATE_USER_REQUEST',
    payload: { user },
  };
}

export function adminCreateUserSuccess() {
  return {
    type: '@user/ADMIN_CREATE_USER_SUCCESS',
    payload: {},
  };
}

export function adminUpdateUserRequest({ user }) {
  return {
    type: '@user/ADMIN_UPDATE_USER_REQUEST',
    payload: { user },
  };
}

export function adminUpdateUserSuccess() {
  return {
    type: '@user/ADMIN_UPDATE_USER_SUCCESS',
    payload: {},
  };
}

export function adminUpdateUserIntegrationsRequest({
  id,
  paymentTypes,
  providerPlans,
  userCredentials,
}) {
  return {
    type: '@user/ADMIN_UPDATE_USER_INTEGRATIONS_REQUEST',
    payload: { id, paymentTypes, providerPlans, userCredentials },
  };
}

export function adminUpdateUserIntegrationsSuccess() {
  return {
    type: '@user/ADMIN_UPDATE_USER_INTEGRATIONS_SUCCESS',
    payload: {},
  };
}

export function getUsersRequest({ setUsers }) {
  return {
    type: '@user/ADMIN_GET_USERS_REQUEST',
    payload: { setUsers },
  };
}

export function getUsersSuccess() {
  return {
    type: '@user/ADMIN_GET_USERS_SUCCESS',
    payload: {},
  };
}

// default

export function getUserRequest() {
  return {
    type: '@user/GET_USER_REQUEST',
    payload: {},
  };
}

export function getUserSuccess({ balance, user }) {
  return {
    type: '@user/GET_USER_SUCCESS',
    payload: { balance, user },
  };
}

export function searchRequest({ setUsers, search, username }) {
  return {
    type: '@user/SEARCH_REQUEST',
    payload: { setUsers, search, username },
  };
}

export function searchSuccess() {
  return {
    type: '@user/SEARCH_SUCCESS',
    payload: {},
  };
}

export function submitDocumentRequest({ category, file }) {
  return {
    type: '@user/SUBMIT_DOCUMENT_REQUEST',
    payload: { category, file },
  };
}

export function submitDocumentSuccess() {
  return {
    type: '@user/SUBMIT_DOCUMENT_SUCCESS',
    payload: {},
  };
}

export const addBankAccountRequest = ({
  accountNumber,
  bankCode,
  branch,
  type,
}) => ({
  type: '@user/ADD_BANK_ACCOUNT_REQUEST',
  payload: {
    accountNumber,
    bankCode,
    branch,
    type,
  },
});

export const addBankAccountSuccess = (bankAccount) => ({
  type: '@user/ADD_BANK_ACCOUNT_SUCCESS',
  payload: { bankAccount },
});

export function updateAccountRequest(data) {
  return {
    type: '@user/UPDATE_ACCOUNT_REQUEST',
    payload: { data },
  };
}

export function updateAccountSuccess(account) {
  return {
    type: '@user/UPDATE_ACCOUNT_SUCCESS',
    payload: { account },
  };
}

export function failure({ status }) {
  return {
    type: '@user/FAILURE',
    payload: { status },
  };
}

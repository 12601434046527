export const UserRole = {
  Admin: 'admin',
  User: 'user',
};
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'pt';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'pt', name: 'Português - LTL', direction: 'ltr' },
];
export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/avatar.png',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const appRoot = '/app';
export const searchPath = `${appRoot}/#`;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const months = [
  {
    label: '01',
    value: '01',
  },
  {
    label: '02',
    value: '02',
  },
  {
    label: '03',
    value: '03',
  },
  {
    label: '04',
    value: '04',
  },
  {
    label: '05',
    value: '05',
  },
  {
    label: '06',
    value: '06',
  },
  {
    label: '07',
    value: '07',
  },
  {
    label: '08',
    value: '08',
  },
  {
    label: '09',
    value: '09',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
];
export const statusColor = {
  active: 'success',
  suspended: 'dark',
  succeeded: 'success',
  paid: 'success',
  refunded: 'danger',
  pending: 'warning',
  canceled: 'danger',
  canceled_security_risk: 'danger',
  failed: 'danger',
  released: 'info',
  blocked: 'danger',
};

import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@webhook/CREATE_WEBHOOK_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@webhook/CREATE_WEBHOOK_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@webhook/DELETE_WEBHOOK_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@webhook/DELETE_WEBHOOK_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@webhook/FAILURE': {
        draft.loading = false;

        break;
      }

      default:
    }
  });

export default reducer;

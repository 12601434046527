import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as Actions from './actions';
import api from '~/services/api';

export function* adminCreateUserSubscription({ payload }) {
  const notification = toast.loading('Criando assinatura...');
  try {
    const { userId, planId, status } = payload;

    yield call(api.post, `/admin/user_subscriptions`, {
      userId,
      planId,
      status,
    });

    toast.update(notification, {
      render: 'Assinatura de usuário criada com sucesso',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.adminCreateSubscriptionSuccess());
  } catch (err) {
    toast.update(notification, {
      render: 'Erro ao criar assinatura do usuário',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.failure({ status: err?.response?.status }));
  }
}

export function* adminUpdateUserSubscription({ payload }) {
  const notification = toast.loading('Atualizando assinatura...');
  try {
    const { id, planId, status } = payload;

    yield call(api.put, `/admin/user_subscriptions/${id}`, {
      planId,
      status,
    });

    toast.update(notification, {
      render: 'Assinatura de usuário atualizado com sucesso',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.adminUpdateSubscriptionSuccess());
  } catch (err) {
    toast.update(notification, {
      render: 'Erro ao atualizar assinatura do usuário',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 5000,
    });
    yield put(Actions.failure({ status: err?.response?.status }));
  }
}

export function* failure({ payload }) {
  const { status } = payload;

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export default all([
  takeLatest(
    '@plans/ADMIN_CREATE_SUBSCRIPTION_REQUEST',
    adminCreateUserSubscription
  ),
  takeLatest(
    '@plans/ADMIN_UPDATE_SUBSCRIPTION_REQUEST',
    adminUpdateUserSubscription
  ),
  takeLatest('@plans/FAILURE', failure),
]);

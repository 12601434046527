export function refreshAdminSignInRequest() {
  return {
    type: '@auth/ADMIN_REFRESH_SIGN_IN_REQUEST',
    payload: {},
  };
}

export function refreshAdminSignInSuccess({ token, refreshToken }) {
  return {
    type: '@auth/ADMIN_REFRESH_SIGN_IN_SUCCESS',
    payload: { token, refreshToken },
  };
}

export function adminSignInRequest({ username, password }) {
  return {
    type: '@auth/ADMIN_SIGN_IN_REQUEST',
    payload: { username, password },
  };
}

export function adminSignInSuccess({ token, refreshToken, user }) {
  return {
    type: '@auth/ADMIN_SIGN_IN_SUCCESS',
    payload: { token, refreshToken, user },
  };
}

export function refreshSignInRequest() {
  return {
    type: '@auth/REFRESH_SIGN_IN_REQUEST',
    payload: {},
  };
}

export function refreshSignInSuccess({ token, refreshToken }) {
  return {
    type: '@auth/REFRESH_SIGN_IN_SUCCESS',
    payload: { token, refreshToken },
  };
}

export function signInRequest({ email, password }) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess({
  token,
  refreshToken,
  balance,
  user,
  loginProfile,
}) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, refreshToken, balance, user, loginProfile },
  };
}

export function signUpRequest({
  firstName,
  lastName,
  ownerDoc,
  ownerEmail,
  ownerPhone,
  dateBirth,
  ownerAddress,
  businessAddress,
  statementDescriptor,
  mcc,
  username,
  password,
  businessName,
  businessEmail,
  businessDoc,
  businessPhone,
  businessOpeningDate,
}) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      firstName,
      lastName,
      ownerDoc,
      ownerEmail,
      ownerPhone,
      dateBirth,
      ownerAddress,
      businessAddress,
      statementDescriptor,
      mcc,
      username,
      password,
      businessName,
      businessEmail,
      businessDoc,
      businessPhone,
      businessOpeningDate,
    },
  };
}

export function signUpSuccess() {
  return {
    type: '@auth/SIGN_UP_SUCCESS',
    payload: {},
  };
}

export function forgotPasswordRequest({ doc, next }) {
  return {
    type: '@auth/FORGOT_PASSWORD_REQUEST',
    payload: { doc, next },
  };
}

export function forgotPasswordSuccess() {
  return {
    type: '@auth/FORGOT_PASSWORD_SUCCESS',
    payload: {},
  };
}

export function forgotPasswordValidationRequest({ code, doc, next }) {
  return {
    type: '@auth/FORGOT_PASSWORD_VALIDATION_REQUEST',
    payload: { code, doc, next },
  };
}

export function forgotPasswordValidationSuccess() {
  return {
    type: '@auth/FORGOT_PASSWORD_VALIDATION_SUCCESS',
    payload: {},
  };
}

export function updateForgottenPasswordRequest({ confirmPassword, password }) {
  return {
    type: '@auth/UPDATE_FORGOTTEN_PASSWORD_REQUEST',
    payload: { confirmPassword, password },
  };
}

export function updateForgottenPasswordSuccess() {
  return {
    type: '@auth/UPDATE_FORGOTTEN_PASSWORD_SUCCESS',
    payload: {},
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
    payload: {},
  };
}

export function failure() {
  return {
    type: '@auth/FAILURE',
    payload: {},
  };
}

export function authFailure({ status }) {
  return {
    type: '@auth/AUTH_FAILURE',
    payload: { status },
  };
}

import { toast } from 'react-toastify';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';

import * as AuthActions from '../auth/actions';
import * as TransactionActions from './actions';
import AppLocale from '~/lang';
import api from '~/services/api';
import history from '~/services/history';
// import { tokenizeCard } from '~/services/zoop';

export function* adminGetTransactions({ payload }) {
  try {
    const { setTransactions, startDate, endDate } = payload;

    if (startDate) {
      const { data } = yield call(api.get, `/admin/transitions`, {
        params: {
          startDate,
          endDate,
        },
      });

      setTransactions(data);
    } else {
      const { data } = yield call(api.get, `/admin/transitions`);

      setTransactions(data);
    }
    yield put(TransactionActions.adminGetTransactionsSuccess());
  } catch (err) {
    yield put(TransactionActions.failure({ status: err?.response?.status }));
  }
}

export function* adminUpdateTransaction({ payload }) {
  try {
    const { transactionId, status, setTransaction } = payload;

    const { data } = yield call(api.put, `/admin/transitions`, {
      status,
      transition_id: transactionId,
    });

    setTransaction(data);
    toast.success('Transação atualizada com sucesso.');

    yield put(TransactionActions.adminUpdateTransactionsSuccess());
  } catch (err) {
    toast.error('Erro ao atualizar a transação.');
    yield put(TransactionActions.failure({ status: err?.response?.status }));
  }
}

export function* getTransactions({ payload }) {
  try {
    const { setTransactions, startDate, endDate } = payload;

    if (startDate) {
      const { data } = yield call(api.get, `/transitions`, {
        params: {
          startDate,
          endDate,
        },
      });

      setTransactions(data);
    } else {
      const { data } = yield call(api.get, `/transitions`);

      setTransactions(data);
    }
    yield put(TransactionActions.getTransactionsSuccess());
  } catch (err) {
    yield put(TransactionActions.failure({ status: err?.response?.status }));
  }
}

export function* createPayment({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];

  try {
    const {
      amount,
      receiverId,
      buyer,
      installments,
      creditCard,
      method,
      paymentLink,
      pixels,
      // sessionID,
    } = payload;
    // const card = creditCard ? yield tokenizeCard(creditCard) : null;

    const { data } = yield call(api.post, '/transfer/unlogged', {
      amount,
      buyer,
      method,
      installments,
      receiverId,
      card: creditCard,
      paymentLinkPath: paymentLink,
      // sessionID,
    });

    yield put(TransactionActions.createPaymentSuccess());

    if (data.status === 'pending' || data.status === 'succeeded') {

      toast.success(messages['alert.payment.success.default']);
      history.push({
        pathname: '#/checkout/succeeded',
        state: { transaction: data, method, pixels, source: 'paymentLink' },
      });
    } else {
      toast.error(messages['alert.payment.default-error']);
    }
  } catch (err) {
    const errorTag = err.response.data?.tag;
    toast.error(
      messages[
        errorTag && errorTag !== '0'
          ? `alert.errors.${errorTag}`
          : 'alert.payment.default-error'
      ]
    );

    yield put(TransactionActions.failure({ status: err?.response?.status }));
  }
}

export function* createPaymentByCheckout({ payload }) {
  const locale = yield select((state) => state.settings.locale);
  const { messages } = AppLocale[locale];

  try {
    const {
      amount,
      buyer,
      installments,
      creditCard,
      method,
      checkout,
      pixels,
      sessionID,
    } = payload;
    // const card = creditCard ? yield tokenizeCard(creditCard) : null;

    const { data } = yield call(api.post, '/public/drop_in_checkout', {
      amount,
      buyer,
      method,
      installments,
      card: creditCard,
      checkout,
      sessionID,
    });

    yield put(TransactionActions.createPaymentByCheckoutSuccess());

    if (data.status === 'pending' || data.status === 'succeeded') {
      toast.success(messages['alert.payment.success']);
      history.push({
        pathname: '#/checkout/succeeded',
        state: { transaction: data, method, pixels },
      });
    } else {
      toast.error(messages['alert.payment.default-error']);
    }
  } catch (err) {
    const errorTag = err.response.data?.tag;
    toast.error(
      messages[
        errorTag && errorTag !== '0'
          ? `alert.errors.${errorTag}`
          : 'alert.payment.default-error'
      ]
    );
    yield put(TransactionActions.failure({ status: err?.response?.status }));
  }
}

export function* getInstallments({ payload }) {
  try {
    const { setInstallments, value, username } = payload;

    const { data } = yield call(api.post, '/installments', { value, username });

    setInstallments(data.installments);
    yield put(TransactionActions.getInstallmentsSuccess());
  } catch (err) {
    yield put(TransactionActions.failure());
  }
}

export function* failure({ payload }) {
  const { status } = payload;

  if (status === 401) {
    yield put(AuthActions.authFailure({ status }));
  }
}

export default all([
  takeLatest(
    '@transactions/ADMIN_GET_TRANSACTIONS_REQUEST',
    adminGetTransactions
  ),
  takeLatest(
    '@transactions/ADMIN_UPDATE_TRANSACTIONS_REQUEST',
    adminUpdateTransaction
  ),
  takeLatest('@transactions/GET_TRANSACTIONS_REQUEST', getTransactions),
  takeLatest('@transactions/CREATE_PAYMENT_REQUEST', createPayment),
  takeLatest(
    '@transactions/CREATE_PAYMENT_BY_CHECKOUT_REQUEST',
    createPaymentByCheckout
  ),
  takeLatest('@transactions/GET_INSTALLMENTS_REQUEST', getInstallments),
  takeLatest('@transactions/FAILURE', failure),
]);

export const createDropInCheckoutRequest = ({
  name,
  productName,
  productImage,
  productDescription,
  pixels,
  amount,
  shippingFee,
  reviews,
  reviewsImages,
  timer,
  paymentTypes,
}) => ({
  type: '@dropInCheckout/CREATE_DROP_IN_CHECKOUT_REQUEST',
  payload: {
    name,
    productName,
    productImage,
    productDescription,
    pixels,
    amount,
    shippingFee,
    reviews,
    reviewsImages,
    timer,
    paymentTypes,
  },
});

export const createDropInCheckoutSuccess = () => ({
  type: '@dropInCheckout/CREATE_DROP_IN_CHECKOUT_SUCCESS',
  payload: {},
});

export const updateDropInCheckoutRequest = ({
  path,
  name,
  productName,
  productImage,
  productDescription,
  pixels,
  amount,
  shippingFee,
  reviews,
  reviewsImages,
  timer,
  paymentTypes,
}) => ({
  type: '@dropInCheckout/UPDATE_DROP_IN_CHECKOUT_REQUEST',
  payload: {
    path,
    name,
    productName,
    productImage,
    productDescription,
    pixels,
    amount,
    shippingFee,
    reviews,
    reviewsImages,
    timer,
    paymentTypes,
  },
});

export const updateDropInCheckoutSuccess = () => ({
  type: '@dropInCheckout/UPDATE_DROP_IN_CHECKOUT_SUCCESS',
  payload: {},
});

export function failure({ status }) {
  return {
    type: '@dropInCheckout/FAILURE',
    payload: { status },
  };
}

import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  signed: false,
  token: null,
  refreshToken: null,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;
        draft.refreshToken = action.payload.refreshToken;

        break;
      }

      case '@auth/REFRESH_SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;
        draft.refreshToken = action.payload.refreshToken;

        break;
      }

      case '@auth/ADMIN_SIGN_IN_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/ADMIN_SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;
        draft.refreshToken = action.payload.refreshToken;

        break;
      }

      case '@auth/ADMIN_REFRESH_SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;
        draft.refreshToken = action.payload.refreshToken;

        break;
      }

      case '@auth/SIGN_UP_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@auth/FORGOT_PASSWORD_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/FORGOT_PASSWORD_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@auth/FORGOT_PASSWORD_VALIDATION_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/FORGOT_PASSWORD_VALIDATION_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@auth/UPDATE_FORGOTTEN_PASSWORD_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/UPDATE_FORGOTTEN_PASSWORD_SUCCESS': {
        draft.loading = false;

        break;
      }

      case '@auth/SIGN_OUT': {
        draft.signed = false;
        draft.token = null;
        draft.refreshToken = null;

        break;
      }
      case '@auth/FAILURE': {
        draft.loading = false;
        draft.signed = false;
        draft.token = null;
        draft.refreshToken = null;

        break;
      }

      default:
    }
  });
export default reducer;

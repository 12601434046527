import produce from 'immer';

import Types from './types';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case Types.CREATE_LOGIN_PROFILE_REQUEST: {
        draft.loading = true;

        break;
      }

      case Types.CREATE_LOGIN_PROFILE_SUCCESS: {
        draft.loading = false;

        break;
      }

      case Types.DELETE_LOGIN_PROFILE_REQUEST: {
        draft.loading = true;

        break;
      }

      case Types.DELETE_LOGIN_PROFILE_SUCCESS: {
        draft.loading = false;

        break;
      }

      case Types.FAILURE: {
        draft.loading = false;

        break;
      }

      default:
    }
  });

export default reducer;
